import React, {Component} from 'react';
import './App.css';
import $ from 'jquery';

import ProjectSlider from "./ContentComponents/ProjectSlider";
import {Link} from 'react-router-dom';
import {withTranslation, Trans} from "react-i18next";
import {Helmet} from "react-helmet";

class BIM extends Component {
    constructor(props) {
        super(props);

        this.state = {
            id : null,
            moreProjects : [],
            loaded : false
        };
    }

    componentDidMount() {
        $(window).scrollTop(0);

        let url = new URL(window.API + '/services/detail/bim');
        fetch(url)
            .then(response => response.json())
            .then(data => this.setState({ id : data.id, moreProjects : data.projects, loaded: true }));
    }

    renderMoreProjects() {
        const { t } = this.props;
        return <div className="container" id="bim-3">

            <section id="bim-more-projects">
                <div className="row">
                    <h2 dangerouslySetInnerHTML={{__html: t('architecture.more-projects.headline', {interpolation: {escape: false}})}} />
                </div>
                <ProjectSlider projects={this.state.moreProjects}/>

            </section>

            { this.state.moreProjects.length > 9 ?
                <div className="row">
                    <Link to={"/" +  t('route.projects') + "?service=" + this.state.id}>
                        <button className="arrow-right-black" dangerouslySetInnerHTML={{__html: t('architecture.more-projects.description', {interpolation: {escape: false}})}} />
                    </Link>
                </div> : null }

        </div>
    }

    render() {
        const {i18n, t} = this.props;

        const video = ' <iframe src="https://player.vimeo.com/video/339961463?title=0&byline=0&portrait=0"\n' +
            '                            style="position:absolute;top:0;left:0;width:100%;height:100%;" frameBorder="0"\n' +
            '                            allow="autoplay; fullscreen" allowFullScreen></iframe>\n' +
            '                <script src="https://player.vimeo.com/api/player.js"></script>';
        return (
            <div>
                <Helmet>
                    <title>{i18n.exists('bim.seo.title') ? t('bim.seo.title') : "Siebert+Talaš"}</title>
                    <meta name="description" content={i18n.exists('bim.seo.description') ? t('bim.seo.description') : ""} />
                    <meta property="og:title" content={i18n.exists('bim.seo.title') ? t('bim.seo.title') : "Siebert+Talaš"} />
                    <meta property="og:description" content={i18n.exists('bim.seo.description') ? t('bim.seo.description') : ""} />
                </Helmet>
                <section id="bim-header">
                    <div className="container content fade-in-website" id="bim">
                        <h1 dangerouslySetInnerHTML={{__html: t('bim.header.headline', {interpolation: {escape: false}})}} />
                        <p className="gray" dangerouslySetInnerHTML={{__html: t('bim.header.description', {interpolation: {escape: false}})}} />
                    </div>
                </section>

                <section id="bim-content">
                    <div className="container">
                            <div className="row">
                                <div className="col-md-6 order-md-2">
                                    <img src="/img/bim-screen.png" className="img-bim-screen" alt=""/>
                                </div>
                                <div className="col-md-6">
                                    <h3 dangerouslySetInnerHTML={{__html: t('bim.content.headline1', {interpolation: {escape: false}})}} />
                                    <span dangerouslySetInnerHTML={{__html: t('bim.content.description1', {interpolation: {escape: false}})}} />
                                </div>
                            </div>

                            <div className="row">
                                <div className="col-md-6">
                                    <h3 dangerouslySetInnerHTML={{__html: t('bim.content.headline2', {interpolation: {escape: false}})}} />
                                    <span dangerouslySetInnerHTML={{__html: t('bim.content.description2', {interpolation: {escape: false}})}} />
                                </div>
                                <div className="col-md-6">
                                    <h3 dangerouslySetInnerHTML={{__html: t('bim.content.headline3', {interpolation: {escape: false}})}} />
                                    <span dangerouslySetInnerHTML={{__html: t('bim.content.description3', {interpolation: {escape: false}})}} />
                                </div>
                            </div>


                            <div className="row third-row">
                                <div className="col-md-6">
                                    <h3 dangerouslySetInnerHTML={{__html: t('bim.content.headline4', {interpolation: {escape: false}})}} />
                                    <span dangerouslySetInnerHTML={{__html: t('bim.content.description4', {interpolation: {escape: false}})}} />
                                </div>
                                <div className="col-md-6">
                                    <span dangerouslySetInnerHTML={{__html: t('bim.content.description5', {interpolation: {escape: false}})}} />
                                </div>
                            </div>

                            <div className="relative-bim" dangerouslySetInnerHTML={{__html: video}} />
                    </div>

                </section>

                { this.state.moreProjects.length > 0 ? this.renderMoreProjects() : null }


            </div>
        );
    }
}

export default  withTranslation()(BIM);
