import React, {Component} from 'react';
import './App.css';
import $ from 'jquery';

import ProjectSlider from "./ContentComponents/ProjectSlider";
import {Link} from 'react-router-dom';
import {withTranslation, Trans} from "react-i18next";
import {Helmet} from "react-helmet";

import ImageDueDiligence from './img/consultation.jpg';
import ImagePPPCS from './img/consultationPppCs.jpg';
import ImagePPPEN from './img/consultationPppEn.jpg';
import ImagePPPRU from './img/consultationPppRu.jpg';
import ImagePPPSK from './img/consultationPppSk.jpg';
import ImageAirportInfrastructure from './img/consultationAirportInfrastructure.jpg';

class Consultation extends Component {
    constructor(props) {
        super(props);

        this.state = {
            id : null,
            moreProjects : [],
            loaded : false
        };
    }

    componentDidMount() {
        $(window).scrollTop(0);

        let url = new URL(window.API + '/services/detail/consultation');
        fetch(url)
            .then(response => response.json())
            .then(data => this.setState({ id : data.id, moreProjects : data.projects, loaded: true }));
    }

    renderMoreProjects() {
        const { t } = this.props;
        return <div className="container" id="bim-3">

            <section id="bim-more-projects">
                <div className="row">
                    <h2 dangerouslySetInnerHTML={{__html: t('architecture.more-projects.headline', {interpolation: {escape: false}})}} />
                </div>
                <ProjectSlider projects={this.state.moreProjects}/>

            </section>

            { this.state.moreProjects.length > 9 ?
                <div className="row">
                    <Link to={"/" +  t('route.projects') + "?service=" + this.state.id}>
                        <button className="arrow-right-black" dangerouslySetInnerHTML={{__html: t('architecture.more-projects.description', {interpolation: {escape: false}})}} />
                    </Link>
                </div> : null }

        </div>
    }

    getPPPImage() {
        switch (this.props.i18n.language) {
            case 'cs': return ImagePPPCS;
            case 'ru': return ImagePPPRU;
            case 'sk': return ImagePPPSK;
            case 'en':
            default:
                return ImagePPPEN;
        }
    }

    render() {
        const {i18n,t} = this.props;

        return (
            <div>
                <Helmet>
                    <title>{i18n.exists('consultation.seo.title') ? t('consultation.seo.title') : "Siebert + Talaš"}</title>
                    <meta name="description" content={i18n.exists('consultation.seo.description') ? t('consultation.seo.description') : ""} />
                    <meta property="og:title" content={i18n.exists('consultation.seo.title') ? t('consultation.seo.title') : "Siebert+Talaš"} />
                    <meta property="og:description" content={i18n.exists('consultation.seo.description') ? t('consultation.seo.description') : ""} />
                </Helmet>
                <section id="bim-header">
                    <div className="container content fade-in-website" id="bim">
                        <h1 dangerouslySetInnerHTML={{__html: t('consultation.header.headline', {interpolation: {escape: false}})}} />
                        <p className="gray" dangerouslySetInnerHTML={{__html: t('consultation.header.description', {interpolation: {escape: false}})}} />
                    </div>
                </section>

                <section id="consultation-content">
                    <div className="container">
                        <h3 className="service-title" dangerouslySetInnerHTML={{__html: t('consultation.content.headline1', {interpolation: {escape: false}})}} />
                        <span dangerouslySetInnerHTML={{__html: t('consultation.content.description1', {interpolation: {escape: false}})}} />

                        <img src={ImageDueDiligence} className="img-consultation" alt={t('consultation.content.headline1', {interpolation: {escape: false}})} />

                        <h3 className="service-title" dangerouslySetInnerHTML={{__html: t('consultation.content.headline2', {interpolation: {escape: false}})}} />
                        <span dangerouslySetInnerHTML={{__html: t('consultation.content.description2', {interpolation: {escape: false}})}} />

                        <img src={this.getPPPImage()} className="img-consultation" alt={t('consultation.content.headline2', {interpolation: {escape: false}})} />

                        <h3 className="service-title" dangerouslySetInnerHTML={{__html: t('consultation.content.headline3', {interpolation: {escape: false}})}} />
                        <span dangerouslySetInnerHTML={{__html: t('consultation.content.description3', {interpolation: {escape: false}})}} />

                        <img src={ImageAirportInfrastructure} className="img-consultation" alt={t('consultation.content.headline3', {interpolation: {escape: false}})} />
                    </div>
                </section>

                { this.state.moreProjects.length > 0 ? this.renderMoreProjects() : null }
            </div>
        );
    }
}

export default  withTranslation()(Consultation);
