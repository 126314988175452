import React, {Component} from 'react';
import {Link} from "react-router-dom";
import projectPlaceholderImage from "../img/placehoder-768x630.png";
import {  withTranslation } from 'react-i18next';

class ProjectSliderItem extends Component {
    render() {
        const { i18n, t } = this.props;

        let slug = this.props.slug[i18n.language] || this.props.id;

        return (
            <div>
                <div className="more-projects-block">
                    <Link to={"/" +  t('route.projects') + "/" + slug}>
                        <img src={this.props.image ? this.props.image : projectPlaceholderImage}  alt=""/>
                        <h3>{this.props.title[i18n.language]}</h3>
                        <span>{this.props.tags[i18n.language]}</span>
                    </Link>
                </div>
            </div>
        );
    }
}

export default withTranslation()(ProjectSliderItem);
