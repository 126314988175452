import React, {Component} from 'react';
import '../App.css';
// or less ideally

import meetUsPersonalYellowLines from "../img/meet-us-personal-yellow-lines.svg";
import meetUsPersonalYellowLinesMobile from "../img/mobile-stripes-partner.svg";
import {Link} from 'react-router-dom';
import {withTranslation} from "react-i18next";
import AnimatedDots from "./AnimatedDots";

class MeetUsPersonal extends Component {
    render() {
        const { t } = this.props;
        return (
            <section className="meet-us-personal">
                <div className="container">
                    <div className="row">
                        <div className="col-md-10 offset-md-1">
                            <img className="desktop float-left d-none d-xl-block" src={meetUsPersonalYellowLines} alt=""/>
                            <h2>{this.props.title}</h2>

                            <div className="meet-us-personal-dots-box">
                                <img className="mobile float-left d-block d-xl-none" src={meetUsPersonalYellowLinesMobile} alt=""/>

                                <div className="float-left">
                                    <Link to={'/'+ t('route.contact')}>
                                        <button className="arrow-right-black">{this.props.button}</button>
                                    </Link>
                                </div>

                                <AnimatedDots cols={5} rows={4} spaces={55} spacesY={38} size={14} color={'gray'} />
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        );
    }
}

export default withTranslation()(MeetUsPersonal);
