import React, { Component } from 'react';
import '../App.css';

import {Link} from 'react-router-dom';
import {withTranslation, Trans} from "react-i18next";


class AboutProjectsBlock extends Component {
    render() {
        const {  t } = this.props;
        return (
            <div>
                <section className="about-projects-block">
                    <div className="row ">

                        <div className="col-md-6 about">
                            <div>
                                <h2>  <Trans i18nKey={'about-projects.about.headline'}/></h2>
                                <span><Trans i18nKey={'about-projects.about.description'}/></span>
                                <Link to={'/'+ t('route.about-us')}><button className="arrow-right-white"><Trans i18nKey={'about-projects.about.button'}/></button></Link>
                            </div>
                        </div>

                        <div className="col-md-6 projects">
                            <div>
                                <h2><Trans i18nKey={'about-projects.projects.headline'}/></h2>
                                <span><Trans i18nKey={'about-projects.projects.description'}/></span>
                                <Link to={'/'+ t('route.projects')}><button  className="arrow-right-white"><Trans i18nKey={'about-projects.projects.button'}/></button></Link>
                            </div>
                        </div>

                    </div>

                </section>

            </div>
        );
    }
}

export default withTranslation()(AboutProjectsBlock);
