import React, {Component} from 'react';
import {Link} from 'react-router-dom';
import './App.css';
import $ from 'jquery';
import SieberLogo from "./img/logo.svg"
import { withTranslation, Trans } from 'react-i18next';
import getLocalizedRoute from "./Utils/localizedRoute";

class Header extends Component {
    componentDidMount() {
        window.headerComponent = this;

        var opened = 0;
        $(".navbar-toggler").click(function () {
            if (opened === 0) {
                $(".language-switcher").show();
                $(".navbar-collapse").show();
                $(".navbar-collapse").addClass("opacity-1");
                opened = 1;
                return;
            } else {
                $(".language-switcher").hide();
                $(".navbar-collapse").hide();
                $(".navbar-collapse").removeClass("opacity-1");
                opened = 0;
            }
        });


        $(".main-menu-ul li").click(function () {
            if (opened === 1) {
                $(".language-switcher").hide();
                $(".navbar-collapse").hide();
                $(".navbar-collapse").removeClass("opacity-1");
                opened = 0;
                return;
            }
        });

        $(".navbar-brand").click(function () {
            if (opened === 1) {
                $(".language-switcher").show();
                $(".navbar-collapse").hide();
                $(".navbar-collapse").removeClass("opacity-1");
                opened = 0;
                return;
            }
        });
    }


    vycistiMenu() {
        $("#main-menu-ul li a").removeClass("active");
    }

    showLanguages(){
        $("#dropdown-language").show();
    }

    getLocalizedRoute(lang) {
        return getLocalizedRoute(lang, this.props.t, this.state);
    }

    render() {
        const {i18n, t } = this.props;

        const changeLanguage = lng => {
            window.Cookies.set('locale', lng);
            i18n.changeLanguage(lng);
        };

        return (
            <div>
                <header className="header">
                    <div className="container header-container">
                        <nav className="navbar navbar-expand-lg">
                            <Link className="navbar-brand" to={"/" + (i18n.language !== 'en' ? i18n.language + '/' : '')}>
                                <img src={SieberLogo} className="menu-logo" alt="logo"/>
                            </Link>

                            <button className="navbar-toggler" type="button">
                                <span className="navbar-toggler-icon"></span>
                            </button>

                            <div className="collapse navbar-collapse no-transition" id="navbarSupportedContent">
                                <ul className="main-menu-ul">
                                    <li id="home-menu-li"><Link to={"/" + (i18n.language !== 'en' ? i18n.language + '/' : '')}><Trans i18nKey={'header.link.home'}/></Link></li>
                                    <li id="projects-menu-li"><Link to={'/' + t('route.projects') } ><Trans i18nKey={'header.link.projects'}/></Link></li>
                                    <li id="services-expertise-menu-li"><Link to={'/' + t('route.services-expertise') } ><Trans i18nKey={'header.link.services-expertise'}/></Link></li>
                                    <li id="about-us-menu-li"><Link to={'/' + t('route.about-us') }><Trans i18nKey={'header.link.about-us'}/></Link></li>
                                    <li id="careers-menu-li"><Link to={'/' + t('route.careers')  }><Trans i18nKey={'header.link.careers'}/></Link></li>
                                    <li id="contact-menu-li"><Link to={'/' +  t('route.contact') }><Trans i18nKey={'header.link.contact'}/></Link></li>
                                </ul>
                            </div>
                        </nav>
                    </div>

                    <div className="language-switcher ">
                        { i18n.language !== "cs" ?  <Link to={this.getLocalizedRoute('cs')} onClick={() => changeLanguage('cs')}>CZ</Link> : null }
                        { i18n.language === "cs" ?  <Link to={this.getLocalizedRoute('sk')} onClick={() => changeLanguage('sk')}>SK</Link> : null }
                    <span className={"inactive"}> / </span>
                        { i18n.language === "en" ?  <Link to={this.getLocalizedRoute('sk')} onClick={() => changeLanguage('sk')}>SK</Link> : null }
                        { i18n.language !== "en" ?   <Link to={this.getLocalizedRoute('en')} onClick={() => changeLanguage('en')}>EN</Link> : null }

                        {/*<span  className={"inactive"}> / </span>*/}
                        {/*{ i18n.language !== "ru" ?  <Link to={this.getLocalizedRoute('ru')} onClick={() => changeLanguage('ru')}>RU</Link> : null }*/}
                        {/*{ i18n.language === "ru" ?  <Link to={this.getLocalizedRoute('sk')} onClick={() => changeLanguage('sk')}>SK</Link> : null }*/}

                    </div>
                </header>
            </div>
        );
    }
}

export default withTranslation()(Header);
