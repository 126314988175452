import React, {Component} from 'react';
import {withTranslation} from "react-i18next";


class HomeAboutSliderItem extends Component {
    render() {
        return (
            <div>
                <div className="row second-row">
                <div className="col-md-5 text-center">
                    <img src={this.props.photo} alt=""/>
                </div>

                <div className="col-md-7 wide-range">
                    <h3>{this.props.title}</h3>
                    <span>{this.props.description}</span>
                </div>
                </div>
            </div>
        );
    }
}

export default withTranslation()(HomeAboutSliderItem);
