import React, {Component} from 'react';
import './App.css';
import $ from 'jquery';
import MeetUsPersonal from "./ContentComponents/MeetUsPersonal";
import {Link} from 'react-router-dom';
import arrowLeftBlack from "./img/arrow-left-black.svg";
import {withTranslation, Trans} from "react-i18next";
import InfinityLoading from "./img/infinity.svg";
import AnimatedDots from "./ContentComponents/AnimatedDots";
import {Helmet} from "react-helmet";

class PeopleDetail extends Component {
    constructor(props) {
        super(props);

        this.state = {
            data: {},
            loaded: false
        };
    }

    componentDidMount() {
        $(window).scrollTop(0);

        let url = new URL(window.API + '/team/detail/' + this.props.match.params.id);
        fetch(url)
            .then(response => response.json())
            .then(data => this.setState({data: data, loaded: true}));
    }

    render() {
        const { i18n, t } = this.props;

        if ( !this.state.loaded ) {
            return <div className="loader-people"><img className="infinityLoading" alt="Loading..." src={InfinityLoading}/></div>
        }

        return (
            <div className="content">
                <Helmet>
                    <title>{this.state.data['og:title'] ? this.state.data['og:title'] : "Siebert+Talaš"}</title>
                    <meta name="description" content={this.state.data['og:description'][i18n.language] ? this.state.data['og:description'][i18n.language] : ""} />
                    <meta property="og:image" content={this.state.data['og:image'] ? this.state.data['og:image'] : ""} />
                    <meta property="og:title" content={this.state.data['og:title'] ? this.state.data['og:title'] : "Siebert+Talaš"} />
                    <meta property="og:description" content={this.state.data['og:description'][i18n.language] ? this.state.data['og:description'][i18n.language] : ""} />
                </Helmet>
                <section id="people-detail-header">
                    <div className="container">
                        { !this.state.loaded ?  <div className="row loaderPeople"><img className="infinityLoading" alt="Loading..." src={InfinityLoading}/>  </div> : null }
                        <div className="back-btn-wrap">
                            <Link to={"/" +  t('route.people')}><button><img src={arrowLeftBlack} alt={""}/><span><Trans i18nKey={'people-detail.back-button'}/></span></button></Link>
                        </div>
                        <div className="row">
                            <div className="col-md-12 col-xl-5 people-detail-headline">
                                <AnimatedDots className="people-detail-white-dots" cols={5} rows={5} spaces={65} spacesY={48} size={16} color={'white'} />
                                <h1>{this.state.data.name}</h1>
                                <img className="people-detail-thumb d-none d-xl-block" src={this.state.data.photo} alt={this.state.data.name}/>
                            </div>
                            <div className="col-md-5 d-xl-none">
                                <img className="people-detail-thumb" src={this.state.data.photo} alt={this.state.data.name}/>
                            </div>
                            <div className="col-md-6 offset-md-1 col-lg-5 offset-lg-2 people-detail-description">
                                <h2><Trans i18nKey={'people-detail.biography.headline'}/></h2>
                                <div dangerouslySetInnerHTML={{__html: this.state.data.biography[i18n.language]}} />
                            </div>
                        </div>
                    </div>
                </section>

                <section id="people-detail-skills">
                    <div className="container" id="people-detail-2">
                        <div className="row ">
                            {this.state.data.skills.map((skill, index) => <div className="col-6 col-md-2 skill"><img src={skill.image} alt={""}/><p>{skill.name[i18n.language]}</p></div>)}
                        </div>
                    </div>
                </section>

                <div id="people-detail-3">
                    <MeetUsPersonal title={<Trans i18nKey={'about-us.meet-us.headline'}/>} button={<Trans i18nKey={'about-us.meet-us.button'}/>} />
                </div>
            </div>
        );
    }
}

export default withTranslation()(PeopleDetail);
