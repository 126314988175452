import React, {Component} from 'react';
import ReactDOM from 'react-dom';
import './App.css';
import $ from 'jquery';

import ProjectSlider from "./ContentComponents/ProjectSlider";
import ProjectsDetailYellowLines from "./img/project-detail-yellow-lines.svg";
import * as Sentry from '@sentry/browser';
import {  withTranslation, Trans } from 'react-i18next';
import ProjectDetailImage from "./ContentComponents/ProjectDetailImage";
import InfinityLoading from "./img/infinity.svg";
import {Helmet} from "react-helmet";

class ProjectsDetail extends Component {

    constructor(props) {
        super(props);
        this.state = {
            error: null,
            eventId: null,
            data: {},
            loaded : false,
            language : null
        };

        this.recalculateFactsHeights = this.recalculateFactsHeights.bind(this);
    }

    componentDidCatch(error, errorInfo) {
        this.setState({error});
        Sentry.withScope(scope => {
            scope.setExtras(errorInfo);
            const eventId = Sentry.captureException(error);
            this.setState({eventId})
        });
    }

    componentDidMount() {
        $(window).scrollTop(0);
        $(window).on('resize', this.recalculateFactsHeights);

        let url = new URL(window.API + '/projects/detail/' + this.props.match.params.id);
        fetch(url)
            .then(response => response.json())
            .then(data => {
                this.setState({ data : data, loaded: true });
                this.recalculateFactsHeights();

                if (typeof window.headerComponent.setState === 'function') {
                    window.headerComponent.setState({project: {id: data.id, slug: data.slug}});
                }
            });
    }

    componentDidUpdate(prevProps) {
        if (prevProps.match.params.id !== this.props.match.params.id) {
            $(window).scrollTop(0);
            this.setState({ loaded: false });
            let url = new URL(window.API + '/projects/detail/' + this.props.match.params.id);
            fetch(url)
                .then(response => response.json())
                .then(data => {
                    this.setState({ data : data, loaded: true });
                    this.recalculateFactsHeights();

                    if (typeof window.headerComponent.setState === 'function') {
                        window.headerComponent.setState({project: {id: data.id, slug: data.slug}});
                    }
                });
        } else if (this.state.language !== this.props.i18n.language) {
            this.setState({ language : this.props.i18n.language });
        }
    }

    recalculateFactsHeights() {
        let $this = $(ReactDOM.findDOMNode(this));
        $this.find('.gray ul li, .border-bottom-gray ul li').removeAttr('style');

        if ($(window).width() >= 768) {
            $this.find('.gray ul li').each(function (index) {
                let $header = $(this);
                let $content = $this.find(`.border-bottom-gray ul li:nth-child(${index + 1})`);

                if ($content.height() > $header.height()) {
                    $header.height($content.height());
                } else if ($content.height() < $header.height()) {
                    $content.height($header.height());
                }
            });
        }
    }


    render() {
        const { i18n, t } = this.props;

        if ( !this.state.loaded ) {
            return <div className="loader-people"><img className="infinityLoading" alt="Loading..." src={InfinityLoading}/></div>
        }

        return (
            <div className="container content fade-in-website" id="projects-detail">
                <Helmet>
                    <title>{this.state.data['og:title'][i18n.language] ? this.state.data['og:title'][i18n.language] : "Siebert+Talaš"}</title>
                    <meta name="description" content={this.state.data['og:description'][i18n.language] ? this.state.data['og:description'][i18n.language] : ""} />
                    <meta property="og:image" content={this.state.data['og:image'] ? this.state.data['og:image'] : ""} />
                    <meta property="og:title" content={this.state.data['og:title'][i18n.language] ? this.state.data['og:title'][i18n.language] : "Siebert+Talaš"} />
                    <meta property="og:description" content={this.state.data['og:description'][i18n.language] ? this.state.data['og:description'][i18n.language] : ""} />
                </Helmet>
                <section id="project-detail-header" style={{backgroundImage: `url(${this.state.data.image_top})`}}>
                    <div className="row">
                    </div>
                </section>
                <section id="project-detail-headline">
                    <div className="row first-row">
                        <img src={ProjectsDetailYellowLines} className="yellow-lines" alt=""/>
                        <div className="col-lg-8 center-headline">
                            <div className="row bottom-line">
                                <div className="col-md-4 col-xl-3 country-container">
                                    <strong><Trans i18nKey={'project-detail.headline.country'}/></strong> {this.state.data.country[i18n.language]}
                                </div>
                                {!(this.state.data.location) ? <div className="col-md-4 col-xl-6" /> :
                                <div className="col-md-4 col-xl-6 location-container">
                                    <strong><Trans i18nKey={'project-detail.headline.location'}/></strong> {this.state.data.location}
                                </div>}

                                {!(this.state.data.completion > 0) ? null :
                                <div className="col-md-4 col-xl-3 text-md-right completion-container">
                                    <strong><Trans i18nKey={'project-detail.headline.completion'}/></strong> {this.state.data.completion}
                                </div>}
                            </div>
                            <h2>{this.state.data.title[i18n.language]}</h2>
                        </div>
                    </div>
                    <div className="row second-row">
                        <div className="col-md-12 center project-detail-description-container">
                            <img src={this.state.data.image} alt={this.state.data.title[i18n.language]} id='project_detail_main_image' />
                            <div className="project-detail-description" dangerouslySetInnerHTML={{__html: this.state.data.description[i18n.language]}} />
                        </div>
                    </div>
                </section>

                <section id="project-detail-facts">
                    <h2 dangerouslySetInnerHTML={{__html: t("project-detail.facts-figures.headline", {interpolation: {escape: false}})}} />

                    <div className="row second-row">
                        <div className="col-md-3 gray d-none d-md-flex">
                            <ul>
                                {this.state.data.appointment ? <li><strong><Trans i18nKey={'project-detail.facts-figures.appointment'}/></strong></li> : null}
                                {this.state.data.area ? <li><strong><Trans i18nKey={'project-detail.facts-figures.area'}/></strong></li> : null}
                                {this.state.data.client ? <li><strong><Trans i18nKey={'project-detail.facts-figures.client'}/></strong></li> : null}
                                {this.state.data.project_manager ? <li><strong><Trans i18nKey={'project-detail.facts-figures.project-manager'}/></strong></li> : null}
                                {this.state.data.st_team ? <li><strong><Trans i18nKey={'project-detail.facts-figures.st-team'}/></strong></li> : null}
                                {this.state.data.structural_engineer ? <li><strong><Trans i18nKey={'project-detail.facts-figures.structural-engineer'}/></strong></li> : null}
                                {this.state.data.landscape_architect ? <li><strong><Trans i18nKey={'project-detail.facts-figures.landscape-architect'}/></strong></li> : null}
                                {this.state.data.completion ? <li><strong><Trans i18nKey={'project-detail.facts-figures.completion'}/></strong></li> : null}
                                {this.state.data.height ? <li><strong><Trans i18nKey={'project-detail.facts-figures.height'}/></strong></li> : null}
                                {this.state.data.collaboration_architect ? <li><strong><Trans i18nKey={'project-detail.facts-figures.collaborating-architect'}/></strong></li> : null}
                                {this.state.data.environmental_engineer ? <li><strong><Trans i18nKey={'project-detail.facts-figures.environmental-engineer'}/></strong></li> : null}
                                {this.state.data.lighting_engineer ? <li><strong><Trans i18nKey={'project-detail.facts-figures.lighting-engineer'}/></strong></li> : null}
                                {this.state.data.others ? <li><strong><Trans i18nKey={'project-detail.facts-figures.others'}/></strong></li> : null}
                            </ul>
                        </div>
                        <div className="col-md-9 border-bottom-gray">
                            <ul>
                                {this.state.data.appointment ? <li><strong className="d-md-none project-detail-facts-mobile-title"><Trans i18nKey={'project-detail.facts-figures.appointment'}/></strong> {this.state.data.appointment}</li> : null}
                                {this.state.data.area ? <li><strong className="d-md-none project-detail-facts-mobile-title"><Trans i18nKey={'project-detail.facts-figures.area'}/></strong> {this.state.data.area} m<sup>2</sup></li> : null}
                                {this.state.data.client ? <li><strong className="d-md-none project-detail-facts-mobile-title"><Trans i18nKey={'project-detail.facts-figures.client'}/></strong> {this.state.data.client}</li> : null}
                                {this.state.data.project_manager ? <li><strong className="d-md-none project-detail-facts-mobile-title"><Trans i18nKey={'project-detail.facts-figures.project-manager'}/></strong> {this.state.data.project_manager}</li> : null}
                                {this.state.data.st_team ? <li><strong className="d-md-none project-detail-facts-mobile-title"><Trans i18nKey={'project-detail.facts-figures.st-team'}/></strong> {this.state.data.st_team}</li> : null}
                                {this.state.data.structural_engineer ? <li><strong className="d-md-none project-detail-facts-mobile-title"><Trans i18nKey={'project-detail.facts-figures.structural-engineer'}/></strong> {this.state.data.structural_engineer}</li> : null}
                                {this.state.data.landscape_architect ? <li><strong className="d-md-none project-detail-facts-mobile-title"><Trans i18nKey={'project-detail.facts-figures.landscape-architect'}/></strong> {this.state.data.landscape_architect}</li> : null}
                                {this.state.data.completion ? <li><strong className="d-md-none project-detail-facts-mobile-title"><Trans i18nKey={'project-detail.facts-figures.completion'}/></strong> {this.state.data.completion}</li> : null}
                                {this.state.data.height ? <li><strong className="d-md-none project-detail-facts-mobile-title"><Trans i18nKey={'project-detail.facts-figures.height'}/></strong> {this.state.data.height} m</li> : null}
                                {this.state.data.collaboration_architect ? <li><strong className="d-md-none project-detail-facts-mobile-title"><Trans i18nKey={'project-detail.facts-figures.collaborating-architect'}/></strong> {this.state.data.collaboration_architect}</li> : null}
                                {this.state.data.environmental_engineer ? <li><strong className="d-md-none project-detail-facts-mobile-title"><Trans i18nKey={'project-detail.facts-figures.environmental-engineer'}/></strong> {this.state.data.environmental_engineer}</li> : null}
                                {this.state.data.lighting_engineer ? <li><strong className="d-md-none project-detail-facts-mobile-title"><Trans i18nKey={'project-detail.facts-figures.lighting-engineer'}/></strong> {this.state.data.lighting_engineer}</li> : null}
                                {this.state.data.others ? <li><strong className="d-md-none project-detail-facts-mobile-title"><Trans i18nKey={'project-detail.facts-figures.others'}/></strong> {this.state.data.others}</li> : null}
                            </ul>
                        </div>
                    </div>
                </section>


                <section id="project-detail-images">
                    {this.state.data.images ? this.state.data.images.map((image, index) => <ProjectDetailImage key={index} src={image} dots={index === 0} />) : null}
                </section>

                <section id="project-detail-more-projects">
                    <h2 dangerouslySetInnerHTML={{__html: t("project-detail.facts-figures.more-projects", {interpolation: {escape: false}})}} />

                    <ProjectSlider projects={this.state.data.more_projects}/>
                </section>
            </div>
        );
    }
}

export default withTranslation()(ProjectsDetail);
