import React, {Component} from 'react';
import './App.css';
import $ from 'jquery';

import ProjectSlider from "./ContentComponents/ProjectSlider";
import {Link} from 'react-router-dom';
import {withTranslation, Trans} from "react-i18next";
import {Helmet} from "react-helmet";

class TrafficInfrastructure extends Component {

    constructor(props) {
        super(props);

        this.state = {
            id : null,
            moreProjects : [],
            loaded : false
        };
    }

    componentDidMount() {
        $(window).scrollTop(0);

        let url = new URL(window.API + '/services/detail/traffic-infrastructure');
        fetch(url)
            .then(response => response.json())
            .then(data => this.setState({ id : data.id, moreProjects : data.projects, loaded: true }));
    }

    renderMoreProjects() {
        const { t } = this.props;
        return <div className="container" id="bim-3">

            <section id="bim-more-projects">
                <div className="row">
                    <h2><Trans i18nKey={'architecture.more-projects.headline'}/></h2>
                </div>
                <ProjectSlider projects={this.state.moreProjects}/>

            </section>

            { this.state.moreProjects.length > 9 ?
                <div className="row">
                    <Link to={"/" + t('route.projects') + "?service=" + this.state.id}>
                        <button className="arrow-right-black"><Trans
                            i18nKey={'architecture.more-projects.description'}/></button>
                    </Link>
                </div> : null }

        </div>
    }

    render() {
        const {i18n, t} = this.props;

        return (
            <div>
                <Helmet>
                    <title>{i18n.exists('trafficinfrastructure.seo.title') ? t('trafficinfrastructure.seo.title') : "Siebert + Talaš"}</title>
                    <meta name="description" content={i18n.exists('trafficinfrastructure.seo.description') ? t('trafficinfrastructure.seo.description') : ""} />
                    <meta property="og:title" content={i18n.exists('trafficinfrastructure.seo.title') ? t('trafficinfrastructure.seo.title') : "Siebert+Talaš"} />
                    <meta property="og:description" content={i18n.exists('trafficinfrastructure.seo.description') ? t('trafficinfrastructure.seo.description') : ""} />
                </Helmet>
                <section id="bim-header">
                    <div className="container content fade-in-website">
                        <h1 dangerouslySetInnerHTML={{__html: t('trafficinfrastructure.header.headline', {interpolation: {escape: false}})}} />
                        <p className="gray" dangerouslySetInnerHTML={{__html: t('trafficinfrastructure.header.description', {interpolation: {escape: false}})}} />
                    </div>
                </section>

                <section id="bim-content">
                    <div className="container">
                            <div className="row">
                                <div className="col-md-6 order-md-2">
                                    <img src="/img/traffic-infrastructure_1.jpg" className="img-bim-screen" alt="" />
                                </div>
                                <div className="col-md-6">
                                    <h3 dangerouslySetInnerHTML={{__html: t('trafficinfrastructure.content.headline1', {interpolation: {escape: false}})}} />
                                    <span dangerouslySetInnerHTML={{__html: t('trafficinfrastructure.content.description1', {interpolation: {escape: false}})}} />
                                </div>
                            </div>

                            <div className="row">
                                <div className="col-md-6">
                                    <h3 dangerouslySetInnerHTML={{__html: t('trafficinfrastructure.content.headline2', {interpolation: {escape: false}})}} />
                                    <span dangerouslySetInnerHTML={{__html: t('trafficinfrastructure.content.description2', {interpolation: {escape: false}})}} />
                                </div>
                                <div className="col-md-6">
                                    <h3 dangerouslySetInnerHTML={{__html: t('trafficinfrastructure.content.headline3', {interpolation: {escape: false}})}} />
                                    <span dangerouslySetInnerHTML={{__html: t('trafficinfrastructure.content.description3', {interpolation: {escape: false}})}} />
                                </div>
                            </div>


                            <div className="row third-row">
                                <div className="col-md-6">
                                    <span dangerouslySetInnerHTML={{__html: t('trafficinfrastructure.content.description4', {interpolation: {escape: false}})}} />
                                </div>
                                <div className="col-md-6">
                                    <span dangerouslySetInnerHTML={{__html: t('trafficinfrastructure.content.description5', {interpolation: {escape: false}})}} />
                                </div>
                            </div>

                            <img src="/img/traffic-infrastructure_2.jpg" alt="" style={{marginBottom: '50px'}}/>
                            <img src="/img/traffic-infrastructure_3.jpg" alt="" style={{marginBottom: '50px'}}/>
                            <img src="/img/traffic-infrastructure_4.jpg" alt="" style={{marginBottom: '50px'}}/>
                            <img src="/img/traffic-infrastructure_5.jpg" alt="" style={{marginBottom: '50px'}}/>
                            <img src="/img/traffic-infrastructure_6.jpg" alt="" style={{marginBottom: '50px'}}/>
                    </div>
                </section>

                { this.state.moreProjects.length > 0 ? this.renderMoreProjects() : null }
            </div>
        );
    }
}

export default  withTranslation()(TrafficInfrastructure);
