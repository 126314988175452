import React, {Component} from 'react';
import {withTranslation} from "react-i18next";
import {Link} from "react-router-dom";


class TeamSliderItem extends Component {
    render() {
        const {i18n, t  } = this.props;

        let slug = this.props.slug || this.props.id;

        return (
            <div>
                <div className="about-team-block">
                    <Link to={"/" +  t('route.people') + "/" + slug}>
                        <img src={this.props.photo} alt="" />

                        <div className="black-overlay">
                            <div className="overlay-inner">
                                <span>{this.props.moto[i18n.language]}</span>
                            </div>
                        </div>
                    </Link>
                    <h5>{this.props.name}</h5>
                </div>

            </div>
        );
    }
}

export default withTranslation()(TeamSliderItem);
