import React, {Component} from 'react';
import {withTranslation} from "react-i18next";

class FilterRow extends Component
{
    render() {
        return <li><input type="checkbox" name={this.props.name} value={this.props.value} checked={this.props.checked} onChange={this.props.onChange}/>{this.props.label}</li>;
    }
}

export default   withTranslation()(FilterRow);