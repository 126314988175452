import React, {Component} from 'react';
import {Link} from "react-router-dom";
import projectPlaceholderImage from "../img/placehoder-768x630.png";
import {withTranslation, Trans} from "react-i18next";

class ProjectBox extends Component
{
    render() {
        const {  i18n, t } = this.props;

        let slug = this.props.slug[i18n.language] || this.props.id;

        return <div className={this.props.side + '-column'}>
            <div className="project-content-block">
                <Link to={"/" +  t('route.projects') + "/" + slug}>
                    <img src={this.props.image ? this.props.image : projectPlaceholderImage}  alt=""/>
                    <div className="project-overlay-hover">
                        <p><Trans i18nKey={'projects.project-overlay.see-projects'}/></p>
                    </div>
                </Link>
            </div>
            <Link to={"/" +  t('route.projects') + "/" + this.props.id} className={'project-link-details'}>
                <h3>{this.props.title[i18n.language]}</h3>
                <span>{this.props.tags[i18n.language]}</span>
            </Link>
        </div>
    }
}

export default  withTranslation()(ProjectBox);