import React, {Component} from 'react';
import {withTranslation} from "react-i18next";
import ProjectTextSK from '../img/projects-text-sk.svg'
import ProjectTextCS from '../img/projects-text-cs.svg'
import ProjectTextEN from '../img/projects-text-en.svg'
import ProjectTextRU from '../img/projects-text-ru.svg'

class ProjectTextBox extends Component
{
    render() {
        const {  i18n } = this.props;

        const img = i18n.language === 'cs' ? ProjectTextCS : (i18n.language === 'sk' ? ProjectTextSK : (i18n.language === 'ru' ? ProjectTextRU : ProjectTextEN));

        return <div className={this.props.side + '-column'}>
            <div className="project-text-content-block">
                <img src={img} alt=""/>
            </div>
        </div>
    }
}

export default  withTranslation()(ProjectTextBox);