import React, { Component } from 'react';
import './App.css';
import $ from 'jquery';

import aboutGlass from './img/aboutGlass.svg';
import aboutBrain from './img/about-brain.svg';
import aboutChampange from './img/aboutChampange.svg';
import aboutPeople from './img/about-people.png';
import OurProcessIcons from "./ContentComponents/OurProcessIcons";
import BIMProjecting from "./ContentComponents/BIMProjecting";
import QualityPoliticsBlock from "./ContentComponents/QualityPoliticsBlock";
import MeetUsPersonal from "./ContentComponents/MeetUsPersonal";

import {Link} from 'react-router-dom';
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";


import Slider from "react-slick";
import TeamSliderItem from "./ContentComponents/TeamSliderItem";
import {withTranslation, Trans} from "react-i18next";
import AnimatedDots from "./ContentComponents/AnimatedDots";
import CountUp from 'react-countup';
import {Helmet} from "react-helmet";

class About extends Component {

    constructor (props){
        super(props);

        this.state = {
            team : [],
            counters : [],
            countUpPlayed : false
        };

        this.countUps = [];

        this.countUpRender = this.countUpRender.bind(this);
        this.onScroll = this.onScroll.bind(this);
    }

    componentDidMount(){
        $(window).scrollTop(0);

        let url = new URL(window.API + '/team/list-partners');
        fetch(url)
            .then(response => response.json())
            .then(data => this.setState({ team : data }));

        fetch(window.API + '/project-counter')
            .then(response => response.json())
            .then(data => this.setState({ counters : data }));

        $(window).on('scroll', this.onScroll);
    }

    componentDidUpdate(_, prevState) {
        this.onScroll();
    }

    componentWillUnmount() {
        $(window).off('scroll', this.onScroll)
    }

    onScroll() {
        var height = $(window).scrollTop();

        if(height > $('.project-numbers').offset().top - $(window).height() * 0.75 && !this.state.countUpPlayed && this.state.counters.length > 0) {
            this.countUps.forEach(function(start) { start(); });

            this.setState({countUpPlayed : true })
        }
    }

    countUpRender({countUpRef, start}) {
        this.countUps.push(start);

        return <span className="number" ref={countUpRef} />;
    }

    countUpFormat(value) {
        return (value < 10) ? '0' + value : value;
    }

    render() {
        var settings = {
           dots: true,
           infinite: false,
           speed: 500,
           slidesToShow: 4,
           slidesToScroll: 4,
           initialSlide: 0,
           responsive: [
               {
                   breakpoint: 1024,
                   settings: {
                       slidesToShow: 3,
                       slidesToScroll: 3,
                       infinite: true,
                       dots: true
                   }
               },
               {
                   breakpoint: 600,
                   settings: {
                       slidesToShow: 2,
                       slidesToScroll: 2,
                       initialSlide: 2
                   }
               },
               {
                   breakpoint: 480,
                   settings: {
                       slidesToShow: 1,
                       slidesToScroll: 1
                   }
               }
           ]
      };
      const { i18n, t } = this.props;

    return (
<div>
    <Helmet>
        <title>{i18n.exists('about-us.seo.title') ? t('about-us.seo.title') : "Siebert+Talaš"}</title>
        <meta name="description" content={i18n.exists('about-us.seo.description') ? t('about-us.seo.description') : ""} />
        <meta property="og:title" content={i18n.exists('about-us.seo.title') ? t('about-us.seo.title') : "Siebert+Talaš"} />
        <meta property="og:description" content={i18n.exists('about-us.seo.description') ? t('about-us.seo.description') : ""} />
    </Helmet>
        <div className="container content fade-in-website" id="about">
            <section id="about-header">
                <h1 dangerouslySetInnerHTML={{__html: t('about-us.header.headline', {interpolation: {escape: false}})}} />
            </section>

            <section id="about-description">
                <div className="row">
                    <div className="col-xl-4 text-xl-center vize">
                        <AnimatedDots className={"vize-dots"} cols={7} rows={8} spaces={30} size={15} />
                        <h2 dangerouslySetInnerHTML={{__html: t('about-us.vision.headline', {interpolation: {escape: false}})}} />
                    </div>
                    <div className="col-xl-8 description">
                        <span dangerouslySetInnerHTML={{__html: t('about-us.vision.description', {interpolation: {escape: false}})}} />
                    </div>
                </div>
            </section>

            <section id="about-description-image-blocks">
                <div className="row">
                    <div className="col-md-4 about-description-image-block" id="about-prace">
                        <div className="black-overlay">
                            <h2 dangerouslySetInnerHTML={{__html: t('about-us.blocks.work.headline', {interpolation: {escape: false}})}} />
                            <span dangerouslySetInnerHTML={{__html: t('about-us.blocks.work.description', {interpolation: {escape: false}})}} />
                        </div>

                    </div>
                    <div className="col-md-4 about-description-image-block" id="about-vasen">
                        <div className="black-overlay">
                            <h2 dangerouslySetInnerHTML={{__html: t('about-us.blocks.passion.headline', {interpolation: {escape: false}})}} />
                            <span dangerouslySetInnerHTML={{__html: t('about-us.blocks.passion.description', {interpolation: {escape: false}})}} />
                        </div>
                    </div>
                    <div className="col-md-4 about-description-image-block" id="about-svoboda">
                        <div className="black-overlay">
                            <h2 dangerouslySetInnerHTML={{__html: t('about-us.blocks.freedom.headline', {interpolation: {escape: false}})}} />
                            <span dangerouslySetInnerHTML={{__html: t('about-us.blocks.freedom.description', {interpolation: {escape: false}})}} />
                        </div>
                    </div>
                </div>
            </section>


            <section id="about-description-history">
                <div className="row">
                    <h2 dangerouslySetInnerHTML={{__html: t('about-us.history.headline', {interpolation: {escape: false}})}} />
                </div>

                <div className="row history-content">

                    <div className="history-date-tags d-none d-md-block">
                        <div className="history-tag history-tag-1"><span>2002</span></div>
                        <div className="history-tag  history-tag-2"><span>2008</span></div>
                        <div className="history-tag  history-tag-3"><span>2010</span></div>
                        <div className="history-tag  history-tag-4"><span>2014</span></div>
                        <div className="history-tag  history-tag-5"><span>2018</span></div>
                    </div>

                    <div className="col-md-6 left">
                        <div className="row">
                            <div className="col-md-9 history-left-1 history-left">
                                <div className="history-tag history-tag--mobile d-block d-md-none"><span>2002</span></div>
                                <span dangerouslySetInnerHTML={{__html: t('about-us.history.content-left.content1', {interpolation: {escape: false}})}} />

                                <div className="yellow mobile">
                                    <span dangerouslySetInnerHTML={{__html: t('about-us.history.content-right.content1', {interpolation: {escape: false}})}} /><img src={aboutBrain} alt=""/>
                                </div>
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-md-9 history-left-2 history-left">
                                <div className="history-tag history-tag--mobile d-block d-md-none"><span>2008</span></div>
                                <span dangerouslySetInnerHTML={{__html: t('about-us.history.content-left.content2', {interpolation: {escape: false}})}} />

                                <div className="history-tag history-tag--mobile history-tag--mobile--2010 d-block d-md-none"><span>2010</span></div>
                                <div className="yellow mobile yellow-glasses">
                                    <span dangerouslySetInnerHTML={{__html: t('about-us.history.content-right.content2', {interpolation: {escape: false}})}} /><img src={aboutGlass} alt=""/>
                                </div>
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-md-9 history-left-3 history-left">
                                <div className="history-tag history-tag--mobile d-block d-md-none"><span>2014</span></div>
                                <span dangerouslySetInnerHTML={{__html: t('about-us.history.content-left.content3', {interpolation: {escape: false}})}} />
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-md-9 history-left-4 history-left">
                                <div className="history-tag history-tag--mobile d-block d-md-none"><span>2018</span></div>
                                <span dangerouslySetInnerHTML={{__html: t('about-us.history.content-left.content4', {interpolation: {escape: false}})}} />

                                <div className="yellow mobile">
                                    <span dangerouslySetInnerHTML={{__html: t('about-us.history.content-right.content3', {interpolation: {escape: false}})}} /><img src={aboutChampange} alt=""/>
                                </div>
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-md-9 history-left-5 history-left">
                                <span dangerouslySetInnerHTML={{__html: t('about-us.history.content-left.content5', {interpolation: {escape: false}})}} />
                            </div>
                        </div>
                    </div>

                    <div className="col-md-6 right">
                        <div className="row history-right-1 history-right">
                            <div className="col-md-8 offset-md-2 yellow">
                                <span dangerouslySetInnerHTML={{__html: t('about-us.history.content-right.content1', {interpolation: {escape: false}})}} /><img src={aboutBrain} alt=""/>
                            </div>
                        </div>
                        <div className="row history-right-2 history-right">
                            <div className="col-md-8 offset-md-2 yellow">
                                <span dangerouslySetInnerHTML={{__html: t('about-us.history.content-right.content2', {interpolation: {escape: false}})}} /><img src={aboutGlass} alt=""/>
                            </div>
                        </div>
                        <div className="row history-right-3 history-right">
                            <div className="col-md-8 offset-md-2 yellow">
                                <span dangerouslySetInnerHTML={{__html: t('about-us.history.content-right.content3', {interpolation: {escape: false}})}} /><img src={aboutChampange} alt=""/>
                            </div>
                        </div>
                    </div>
                </div>

                <div className="row">
                    <div className="col-md-1">

                    </div>
                    <div className="col-md-10 project-numbers text-center">
                        <div className="row">
                            {this.state.counters.map((item, key) => <div key={key} className="col-md-3 col-6 project-number-box">
                                <CountUp start={0} end={item.count} duration={3} useEasing={true} formattingFn={ this.countUpFormat }>{ this.countUpRender }</CountUp>
                                <span>{ item.title[i18n.language] }</span>
                            </div>)}
                        </div>

                    </div>
                    <div className="col-md-1">

                    </div>

                </div>

            </section>

            <section id="about-people">
                <div className="row">
                    <div className="col-lg-6 col-md-5">
                    <img src={aboutPeople} alt=""/>
                    </div>
                    <div className="col-lg-6 col-md-7 people-description">
                        <h2 dangerouslySetInnerHTML={{__html: t('about-us.people.headline', {interpolation: {escape: false}})}} />
                        <span dangerouslySetInnerHTML={{__html: t('about-us.people.description', {interpolation: {escape: false}})}} />
                        <Link to={'/'+ t('route.people')}>
                            <button className="arrow-right-black" dangerouslySetInnerHTML={{__html: t('about-us.people.look-people-btn', {interpolation: {escape: false}})}} />
                        </Link>
                    </div>
                </div>
            </section>

            <section id="about-team">
                <div className="row" />

                <Slider {...settings}>
                    {this.state.team ? this.state.team.map((people, index) => <TeamSliderItem key={index} id={people.id} photo={people.photo} name={people.name} slug={people.slug} moto={people.moto}/>) : null}
                </Slider>
            </section>

            <section id="about-process">
                <div className="container">
                    <h2 dangerouslySetInnerHTML={{__html: t('home.our-process.title', {interpolation: {escape: false}})}} />

                    <div className="row">
                        <div className="col-lg-8">
                            <span dangerouslySetInnerHTML={{__html: t('home.our-process.description', {interpolation: {escape: false}})}} />
                        </div>
                        <div className="col-lg-4 d-none d-lg-block">
                            <AnimatedDots cols={5} rows={5} spaces={45} spacesY={35} size={15} />
                        </div>
                    </div>

                    <OurProcessIcons/>
                </div>
            </section>

            <BIMProjecting/>


        </div>

       <MeetUsPersonal title={<Trans i18nKey={'about-us.meet-us.headline'}/>} button={<Trans i18nKey={'about-us.meet-us.button'}/>} />
</div>
    );
  }
}

export default withTranslation()(About);
