import React, {Component} from 'react';
import './App.css';
import $ from 'jquery';
import AboutProjectsBlock from './ContentComponents/AboutProjectsBlock';
import CareersSection2Img from "./img/careers-section-2-img.png";
import Typical1 from "./img/typical1.png";
import Typical2 from "./img/typical2.png";
import Typical3 from "./img/typical3.png";
import Typical1317 from "./img/typical1317.jpg";
import Typical17 from "./img/typical17.jpg";
import JobRowComponent from "./ContentComponents/JobRowComponent";
import {withTranslation, Trans} from "react-i18next";
import Slider from "react-slick";
import TypicalDaySliderItem from "./ContentComponents/TypicalDaySliderItem";
import {Link as LinkScroll} from "react-scroll";
import AnimatedDots from "./ContentComponents/AnimatedDots";
import {Helmet} from "react-helmet";

class Careers extends Component {
    constructor(props) {
        super(props);

        this.state = {
            jobs: []
        };
    }

    componentDidMount() {
        $(window).scrollTop(0);

        fetch(window.API + '/jobs/list')
            .then(response => response.json())
            .then(data => this.setState({jobs: data}));
    }

    render() {
        const { i18n,t } = this.props;

        var settings = {
            dots: true,
            infinite: false,
            speed: 500,
            slidesToShow: 3,
            slidesToScroll: 3,
            initialSlide: 0,
            responsive: [
                {
                    breakpoint: 1200,
                    settings: {
                        slidesToShow: 3,
                        slidesToScroll: 3,
                        infinite: false,
                        dots: true
                    }
                },
                {
                    breakpoint: 992,
                    settings: {
                        slidesToShow: 2,
                        slidesToScroll: 2,
                        initialSlide: 2
                    }
                },
                {
                    breakpoint: 640,
                    settings: {
                        slidesToShow: 1,
                        slidesToScroll: 1
                    }
                }
            ]
        };
        return (
            <div className="container content fade-in-website" id="careers">
                <Helmet>
                    <title>{i18n.exists('careers.seo.title') ? t('careers.seo.title') : "Siebert+Talaš"}</title>
                    <meta name="description" content={i18n.exists('careers.seo.description') ? t('careers.seo.description') : ""} />
                    <meta property="og:title" content={i18n.exists('careers.seo.title') ? t('careers.seo.title') : "Siebert+Talaš"} />
                    <meta property="og:description" content={i18n.exists('careers.seo.description') ? t('careers.seo.description') : ""} />
                </Helmet>
                <section id="careers-header">
                    <div className="row">
                        <div className="col-xl-4 careers-headline">
                            <AnimatedDots cols={4} rows={5} spaces={50} spacesY={35} size={14} />
                            <h1 dangerouslySetInnerHTML={{__html: t('careers.header.headline', {interpolation: {escape: false}})}} />
                        </div>
                        <div className="col-xl-7 offset-xl-1 careers-header-description">
                            <span dangerouslySetInnerHTML={{__html: t('careers.header.description', {interpolation: {escape: false}})}} />
                            <LinkScroll   to="careers-recruiter-process"
                                          spy={true}
                                          smooth={true}
                                          offset={-70}
                                          duration= {500}>
                                <button className="arrow-down-white button-bottom" dangerouslySetInnerHTML={{__html: t('home.header.show-more', {interpolation: {escape: false}})}} />
                            </LinkScroll>
                        </div>
                    </div>
                </section>

                <section id="careers-section-2">
                    <div className="row">
                        <div className="col-md-6">
                            <img className="careers-section-2-img" src={CareersSection2Img} alt=""/>
                        </div>
                        <div className="col-md-6 careers-section-2-description">
                            <h3 dangerouslySetInnerHTML={{__html: t('careers.why-work-for-us.headline', {interpolation: {escape: false}})}} />
                            <span dangerouslySetInnerHTML={{__html: t('careers.why-work-for-us.content', {interpolation: {escape: false}})}} />
                        </div>
                    </div>
                </section>

                <section id="careers-typical">




                    <h2 dangerouslySetInnerHTML={{__html: t('careers.typical-day.headline', {interpolation: {escape: false}})}} />
                    <div>
                        <Slider {...settings}>
                            <TypicalDaySliderItem photo={Typical1} description={<Trans i18nKey={'careers.typical-day08.description'}/>} time={"8:30"}/>
                            <TypicalDaySliderItem photo={Typical2} description={<Trans i18nKey={'careers.typical-day0911.description'}/>} time={"9 - 11"}/>
                            <TypicalDaySliderItem photo={Typical3} description={<Trans i18nKey={'careers.typical-day12.description'}/>} time={"12"}/>
                            <TypicalDaySliderItem photo={Typical1317} description={<Trans i18nKey={'careers.typical-day1317.description'}/>} time={"13 - 17"}/>
                            <TypicalDaySliderItem photo={Typical17} description={<Trans i18nKey={'careers.typical-day17.description'}/>} time={"17"}/>
                        </Slider>
                    </div>
                </section>

                <section id="careers-recruiter-process">
                    <div className="row">
                        <div className="col-lg-5 careers-recruiter-headline">
                            <h2 dangerouslySetInnerHTML={{__html: t('careers.recruiters.headline', {interpolation: {escape: false}})}} />
                            <AnimatedDots cols={4} rows={4} spaces={63} spacesY={45} size={17} color={'gray'} />
                        </div>

                        <div className="col-lg-7 careers-recruiter-content">
                            <ol>
                                <li><div className="careers-recruiter-line"><span dangerouslySetInnerHTML={{__html: t('careers.recruiters.line1', {interpolation: {escape: false}})}} /></div></li>
                                <li><div className="careers-recruiter-line"><span dangerouslySetInnerHTML={{__html: t('careers.recruiters.line2', {interpolation: {escape: false}})}} /></div></li>
                                <li><div className="careers-recruiter-line"><span dangerouslySetInnerHTML={{__html: t('careers.recruiters.line3', {interpolation: {escape: false}})}} /></div></li>
                                <li><div className="careers-recruiter-line"><span dangerouslySetInnerHTML={{__html: t('careers.recruiters.line4', {interpolation: {escape: false}})}} /></div></li>
                                <li><div className="careers-recruiter-line"><span dangerouslySetInnerHTML={{__html: t('careers.recruiters.line5', {interpolation: {escape: false}})}} /></div></li>
                                <li><div className="careers-recruiter-line"><span dangerouslySetInnerHTML={{__html: t('careers.recruiters.line6', {interpolation: {escape: false}})}} /></div></li>
                            </ol>
                        </div>
                    </div>
                </section>

                <section id="careers-jobs">
                    <h2 dangerouslySetInnerHTML={{__html: t('careers.jobs.headline', {interpolation: {escape: false}})}} />
                    {this.state.jobs ? this.state.jobs.map((job, index) => <JobRowComponent key={index} id={job.id} jobTitle={job.jobTitle} description={job.content[i18n.language]} location={job.location} type={job.type}/>) : null}
                </section>

                <AboutProjectsBlock/>
            </div>
        );
    }
}

export default withTranslation()(Careers);
