import React, {Component} from 'react';
import '../App.css';


import process1icon from "../img/home-process-1.svg";
import process2icon from "../img/home-process-2.svg";
import process3icon from "../img/home-process-3.svg";
import process4icon from "../img/home-process-4.svg";
import process5icon from "../img/home-process-5.svg";
import process6icon from "../img/home-process-6.svg";
import process7icon from "../img/home-process-7.svg";

import {Trans, withTranslation} from "react-i18next";
import ProcessIcon from "./ProcessIcon";


class OurProcessIcons extends Component {
    render() {
        return (
            <div>
                <div className="our-process-icons">
                    <div className="row row-1">
                        <ProcessIcon number={1} img={process1icon} description={<Trans i18nKey={'our-process.icon1.headline'}/>}/>
                        <ProcessIcon number={2} img={process2icon} description={<Trans i18nKey={'our-process.icon2.headline'}/>}/>
                        <ProcessIcon number={3} img={process3icon} description={<Trans i18nKey={'our-process.icon3.headline'}/>}/>
                        <ProcessIcon number={4} img={process4icon} description={<Trans i18nKey={'our-process.icon4.headline'}/>}/>
                    </div>
                    <div className="row row-2 justify-content-md-center">
                        <ProcessIcon number={5} img={process5icon} description={<Trans i18nKey={'our-process.icon5.headline'}/>}/>
                        <ProcessIcon number={6} img={process6icon} description={<Trans i18nKey={'our-process.icon6.headline'}/>}/>
                        <ProcessIcon number={7} img={process7icon} description={<Trans i18nKey={'our-process.icon7.headline'}/>}/>
                    </div>
                </div>
            </div>
        );
    }
}

export default withTranslation()(OurProcessIcons);
