import React, {Component} from 'react';
import '../App.css';

import {Link} from 'react-router-dom';

import {withTranslation, Trans} from "react-i18next";
import AnimatedDots from "./AnimatedDots";


class BIMProjecting extends Component {


    componentDidMount() {

    }


    render() {
        const {t} = this.props;

        const video = '<iframe src="https://player.vimeo.com/video/339961463?title=0&byline=0&portrait=0" id="bim_video" frameBorder="0" allow="autoplay; fullscreen" allowFullScreen></iframe>' +
            '          <script src="https://player.vimeo.com/api/player.js"></script>';

        return (
            <section className="bim-projecting">
                <div className="row bim-header">
                    <div className="col-md-8 col-lg-6 bim-header-inner">
                        <h2 dangerouslySetInnerHTML={{__html: t('bim-projecting.header.headline', {interpolation: {escape: false}})}} />
                        <span dangerouslySetInnerHTML={{__html: t('bim-projecting.header.description', {interpolation: {escape: false}})}} />
                    </div>
                </div>
                <div className="row">
                    <div className="col-md-6 bim-image">
                        <div dangerouslySetInnerHTML={{__html: video}}/>
                    </div>
                    <div className="col-md-6 bim-list">
                        <ul>
                            <li>
                                <div className="bim-list-inner" dangerouslySetInnerHTML={{__html: t('bim-projecting.list.content1', {interpolation: {escape: false}})}} />
                            </li>
                            <li>
                                <div className="bim-list-inner" dangerouslySetInnerHTML={{__html: t('bim-projecting.list.content2', {interpolation: {escape: false}})}} />
                            </li>
                            <li>
                                <div className="bim-list-inner" dangerouslySetInnerHTML={{__html: t('bim-projecting.list.content3', {interpolation: {escape: false}})}} />
                            </li>
                            <li>
                                <div className="bim-list-inner" dangerouslySetInnerHTML={{__html: t('bim-projecting.list.content4', {interpolation: {escape: false}})}} />
                            </li>
                            <li>
                                <div className="bim-list-inner" dangerouslySetInnerHTML={{__html: t('bim-projecting.list.content5', {interpolation: {escape: false}})}} />
                            </li>
                        </ul>
                    </div>
                </div>
                <div className="row bim-bottom">
                    <div className="col-md-6 bim-bottom-text">
                        <span dangerouslySetInnerHTML={{__html: t('bim-projecting.bottom.description', {interpolation: {escape: false}})}} />
                    </div>
                    <div className="col-md-6 bim-more-btn">
                        <AnimatedDots cols={8} rows={5} spaces={30} size={10}/>
                        <Link to={'/' + t('route.bim')}>
                            <button className="arrow-right-black">
                                <Trans i18nKey={'bim-projecting.bottom.more-button'}/>
                            </button>
                        </Link>
                    </div>
                </div>
            </section>
        );
    }
}

export default withTranslation()(BIMProjecting);
