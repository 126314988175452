import React, {Component} from 'react';
import {withTranslation} from "react-i18next";
import {Link} from "react-router-dom";


class PeopleAssociatePartnerItem extends Component {
    render() {
        const { t } = this.props;

        let slug = this.props.slug || this.props.id;

        if (this.props.photo === null) { return null; }

        return (
            <div className="col-md-4 people-asociated-partner">
                { this.props.country ? <div className="associate-language-badge">
                    <span>{this.props.country}</span>
                </div> : null }
                <Link to={"/" +  t('route.people') + "/" + slug}>
                    <img src={this.props.photo} alt={this.props.name} />
                </Link>
            </div>
        );
    }
}

export default withTranslation()(PeopleAssociatePartnerItem);
