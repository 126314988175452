import React, {Component} from "react";
import plakatSk from "../img/plakat-sk.svg";
const $ = window.jQuery;

export default class Poster extends Component
{
    componentDidMount() {
        // console.log($('#plakat'));
        $('#plakat').modal('show');
    }

    render()
    {
        return <div className="modal fade" id="plakat" tabIndex="-1" aria-hidden="true">
            <div className="modal-dialog modal-xl">
                <div className="modal-content">
                    <div className="modal-header">
                        <button type="button" className="close" data-dismiss="modal" aria-label="Close">
                            <span aria-hidden="true">&times;</span>
                        </button>
                    </div>
                    <div className="modal-body">
                        <object data={plakatSk} style={{maxWidth: '100%'}} type="image/svg+xml" />
                    </div>
                </div>
            </div>
        </div>
    }
}