import React, {Component} from 'react';
import './App.css';
import $ from 'jquery';
import MeetUsPersonal from "./ContentComponents/MeetUsPersonal";
import {withTranslation, Trans} from "react-i18next";
import PeoplePartnerItem from "./ContentComponents/PeoplePartnerItem";
import PeopleAssociatePartnerItem from "./ContentComponents/PeopleAssociatePartnerItem";
import AnimatedDots from "./ContentComponents/AnimatedDots";
import {Helmet} from "react-helmet";


class People extends Component {
    constructor(props) {
        super(props);

        this.state = {
            partners: [],
            associate_partners: [],
            associates: []
        }
    }


    componentDidMount() {
        $(window).scrollTop(0);

        let url = new URL(window.API + '/team/list?type=partner');
        fetch(url)
            .then(response => response.json())
            .then(data => this.setState({partners: data}));

        url = new URL(window.API + '/team/list?type=associate_partner');
        fetch(url)
            .then(response => response.json())
            .then(data => this.setState({associate_partners: data}));

        url = new URL(window.API + '/team/list?type=associate');
        fetch(url)
            .then(response => response.json())
            .then(data => this.setState({associates: data}));
    }


    render() {
        const {i18n, t} = this.props;

        return (
            <div className="content">
                <Helmet>
                    <title>{i18n.exists('people.seo.title') ? t('people.seo.title') : "Siebert+Talaš"}</title>
                    <meta name="description" content={i18n.exists('people.seo.description') ? t('people.seo.description') : ""} />
                    <meta property="og:title" content={i18n.exists('people.seo.title') ? t('people.seo.title') : "Siebert+Talaš"} />
                    <meta property="og:description" content={i18n.exists('people.seo.description') ? t('people.seo.description') : ""} />
                </Helmet>
                <section id="people-header">
                    <div className="container">
                        <div className="row">
                            <div className="col-xl-4 people-headline">
                                <AnimatedDots cols={4} rows={5} spaces={50} spacesY={35} size={14} color="white" />
                                <h1 dangerouslySetInnerHTML={{__html: t("people.header.headline", {interpolation: {escape: false}})}} />
                            </div>
                            <div className="col-xl-7 offset-xl-1 people-description">
                                <span dangerouslySetInnerHTML={{__html: t("people.header.description", {interpolation: {escape: false}})}} />
                            </div>

                        </div>
                    </div>
                </section>

                <section id="people-partners">
                    <div className="container fade-in-website">
                        <h2 dangerouslySetInnerHTML={{__html: t("people.partners.headline", {interpolation: {escape: false}})}} />

                        <div className="row people-partners-row">
                            {this.state.partners ? this.state.partners.map((people, index) => <PeoplePartnerItem
                                key={index} id={people.id} photo={people.photo} name={people.name} slug={people.slug}
                                moto={people.moto}/>) : null}
                        </div>
                    </div>
                </section>

                <section id="people-asociated-partners">
                    <div className="container">
                        <h2 dangerouslySetInnerHTML={{__html: t("people.associates-partners.headline", {interpolation: {escape: false}})}} />

                        <div className="row">
                            {this.state.associate_partners ? this.state.associate_partners.map((people, index) =>
                                <PeopleAssociatePartnerItem key={index} id={people.id} country={people.country}
                                                            photo={people.photo} name={people.name}
                                                            slug={people.slug} moto={people.moto}/>) : null}
                        </div>
                    </div>
                </section>

                <section id="people-asociates">
                    <div className="container fade-in-website">
                        <h2 dangerouslySetInnerHTML={{__html: t("people.associates.headline", {interpolation: {escape: false}})}} />

                        <span>
                            {this.state.associates ? this.state.associates.map((people, index) => (index !== 0 ? ' / ' : '') + people.name) : null}
                        </span>
                    </div>
                </section>

                <div id="people-5">
                    <MeetUsPersonal title={<Trans i18nKey={'people.meet-us.headline'}/>} button={<Trans i18nKey={'people.meet-us.button'}/>}/>
                </div>

            </div>
        );
    }
}

export default withTranslation()(People);
