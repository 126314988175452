import React, {Component} from 'react';
import {withTranslation} from "react-i18next";
import {Link} from "react-router-dom";


class PeoplePartnerItem extends Component {
    render() {
        const {i18n, t } = this.props;

        let slug = this.props.slug || this.props.id;

        if (this.props.photo === null) { return null; }

        return (
            <div className="col-md-4 people-partners-block">
                <Link to={"/" +  t('route.people') + "/" + slug}>
                    <img src={this.props.photo} alt="{this.props.name}" />

                    <div className="black-overlay">
                        <div className="overlay-inner">
                            <h4>{this.props.name}</h4>
                            <span>{this.props.moto[i18n.language]}</span>
                        </div>
                    </div>
                </Link>
            </div>
        );
    }
}

export default withTranslation()(PeoplePartnerItem);
