import React, {Component} from 'react';
import './App.css';
import {withTranslation, Trans} from "react-i18next";
import $ from 'jquery';

class PrivacyPolicy extends Component {
    componentWillMount() {
        $(window).scrollTop(0);
    }

    render() {
        const { t } = this.props;

        return (
            <div>
                <div className="container text-left" id="privacy-policy">
                    <h1 ><Trans i18nKey={'privacy-policy.headline'}/></h1>
                    <h1 dangerouslySetInnerHTML={{__html: t("privacy-policy.headline", {interpolation: {escape: false}})}} />

                    <div dangerouslySetInnerHTML={{__html: t("privacy-policy.content", {interpolation: {escape: false}})}} />
                </div>
            </div>
        );
    }
}

export default withTranslation()(PrivacyPolicy);
