import React, {Component} from 'react';
import './App.css';
import $ from 'jquery';

import AboutProjectsBlock from './ContentComponents/AboutProjectsBlock';
import ContactYellowLine from "./img/contact-yellow-lines.svg";
import MapaContact from "./img/mapa-contact.svg";
import {withTranslation, Trans} from "react-i18next";
import ContactOfficeManager from "./ContentComponents/ContactOfficeManager";
import AnimatedDots from "./ContentComponents/AnimatedDots";
import {Helmet} from "react-helmet";

class Contact extends Component {
    constructor(props) {
        super(props);
        const { t } = this.props;

        this.state = {
            officeManagers : [],
            loaded : false,
            formSending : false,
            formSentSuccessfully : false,
            formError : null,
            formInvalidFields : {}
        };

        this.onSubmit = this.onSubmit.bind(this);

        t('contact.form.validation.empty');
        t('contact.form.validation.invalid_email');
        t('contact.form.validation.gdpr-invalid');
    }

    componentDidMount() {
        $(window).scrollTop(0);

        let url = new URL(window.API + '/team/list?type=office_manager');
        fetch(url)
            .then(response => response.json())
            .then(_data => {
                let data = [];

                let _prev = [];
                _data.forEach(function(person, index) {
                    if (person.country !== 'SK' && data.length === 0) {
                        _prev.push(person);
                    } else {
                        data.push(person);

                        if (_prev.length > 0) {
                            data.push(..._prev);
                            _prev = [];
                        }
                    }
                });

                this.setState({ officeManagers : data });
            });
    }

    onSubmit(event) {
        event.preventDefault();
        const { i18n } = this.props;
        const self = this;
        self.setState({
            formSending : true
        });
        let $form = $(event.target);

        $.ajax({
            'method' : 'post',
            'data' : $form.serialize(),
            'url' : window.API + '/contact/send-message?locale=' + i18n.language,
            'success' : function(data) {

                if(data.status === 'success') {
                    self.setState({
                        formSending : false,
                        formSentSuccessfully : true
                    });
                    $form[0].reset();
                    setTimeout(function() { self.setState({ formSentSuccessfully : false }); }, 10000);
                } else {
                    self.setState({
                        formSending : false,
                        formSentSuccessfully : false,
                        formError : data.message,
                        formInvalidFields : data.errors
                    });
                }
            }
        });

        return false;
    }


    render() {
        const {i18n, t} = this.props;

        return (
            <div>
                <Helmet>
                    <title>{i18n.exists('contact.seo.title') ? t('contact.seo.title') : "Siebert+Talaš"}</title>
                    <meta name="description" content={i18n.exists('contact.seo.description') ? t('contact.seo.description') : ""} />
                    <meta property="og:title" content={i18n.exists('contact.seo.title') ? t('contact.seo.title') : "Siebert+Talaš"} />
                    <meta property="og:description" content={i18n.exists('contact.seo.description') ? t('contact.seo.description') : ""} />
                </Helmet>
                <div className="container content fade-in-website" id="contact">
                    <section id="contact-header">
                        <div className="row second-row">
                            <div className="col-xl-3 contact-headline">
                                <AnimatedDots cols={4} rows={5} spaces={50} spacesY={35} size={14} />
                                <h1 dangerouslySetInnerHTML={{__html: t('contact.header.headline', {interpolation: {escape: false}})}} />
                            </div>
                            <div className="col-md-6 col-xl-4">
                                <div className="bratislava">
                                    <h2 dangerouslySetInnerHTML={{__html: t('contact.address.bratislava.headline', {interpolation: {escape: false}})}} />
                                    <h3 dangerouslySetInnerHTML={{__html: t('contact.address.bratislava.company', {interpolation: {escape: false}})}} /><br/>
                                    <span style={{marginBottom: '10px'}} dangerouslySetInnerHTML={{__html: t('contact.address.bratislava.line1', {interpolation: {escape: false}})}} /><br/>
                                    <span dangerouslySetInnerHTML={{__html: t('contact.address.bratislava.line2', {interpolation: {escape: false}})}} /><br/>
                                    <span style={{marginBottom: '10px'}} dangerouslySetInnerHTML={{__html: t('contact.address.bratislava.line3', {interpolation: {escape: false}})}} /><br/>
                                    <span dangerouslySetInnerHTML={{__html: t('contact.address.bratislava.line4', {interpolation: {escape: false}})}} />
                                </div>
                            </div>
                            <div className="col-md-6 col-xl-4">
                                <div className="praha">
                                    <h2 dangerouslySetInnerHTML={{__html: t('contact.address.prague.headline', {interpolation: {escape: false}})}} />
                                    <h3 dangerouslySetInnerHTML={{__html: t('contact.address.prague.company', {interpolation: {escape: false}})}} /><br/>
                                    <span dangerouslySetInnerHTML={{__html: t('contact.address.prague.line1', {interpolation: {escape: false}})}} /><br/>
                                    <span style={{marginBottom: '10px'}} dangerouslySetInnerHTML={{__html: t('contact.address.prague.line2', {interpolation: {escape: false}})}} /><br/>
                                    <span style={{marginBottom: '10px'}} dangerouslySetInnerHTML={{__html: t('contact.address.prague.line3', {interpolation: {escape: false}})}} /><br/>
                                    <span dangerouslySetInnerHTML={{__html: t('contact.address.prague.line4', {interpolation: {escape: false}})}} />
                                </div>
                            </div>
                        </div>

                    </section>

                    <section id="contact-form">
                        <h3 dangerouslySetInnerHTML={{__html: t('contact.form.headline', {interpolation: {escape: false}})}} />
                        <div className="row">

                            <div className="col-md-10 col-lg-8 col-xl-6 form-holder">
                                <img src={ContactYellowLine} className="yellow-lines" alt=""/>
                                { this.state.formSentSuccessfully ? <div className='contact-form-success'>
                                        <p dangerouslySetInnerHTML={{__html: t('contact.form.success-message', {interpolation: {escape: false}})}} />
                                </div> :
                                    <form onSubmit={this.onSubmit}>
                                        <span><span className="yellow">*</span> <span dangerouslySetInnerHTML={{__html: t('contact.form.name', {interpolation: {escape: false}})}} /></span><br/>
                                        <div className={"input-box" + (Object.keys(this.state.formInvalidFields).includes('name') ? ' with-error' : '')}>
                                            <input type="text" name="name"/>
                                            { Object.keys(this.state.formInvalidFields).includes('name') ? <span className="invalid-message" dangerouslySetInnerHTML={{__html: t(this.state.formInvalidFields.name, {interpolation: {escape: false}})}} /> : null }
                                        </div>

                                        <span><span className="yellow">*</span> <span dangerouslySetInnerHTML={{__html: t('contact.form.email', {interpolation: {escape: false}})}} /></span><br/>
                                        <div className={"input-box" + (Object.keys(this.state.formInvalidFields).includes('email') ? ' with-error' : '')}>
                                            <input type="text" name="email"/>
                                            { Object.keys(this.state.formInvalidFields).includes('email') ? <span className="invalid-message" dangerouslySetInnerHTML={{__html: t(this.state.formInvalidFields.email, {interpolation: {escape: false}})}} /> : null }
                                        </div>

                                        <span><span dangerouslySetInnerHTML={{__html: t('contact.form.company', {interpolation: {escape: false}})}} /></span><br/>
                                        <div className={"input-box" + (Object.keys(this.state.formInvalidFields).includes('company') ? ' with-error' : '')}>
                                            <input type="text" name="company"/>
                                            { Object.keys(this.state.formInvalidFields).includes('company') ? <span className="invalid-message" dangerouslySetInnerHTML={{__html: t(this.state.formInvalidFields.company, {interpolation: {escape: false}})}} /> : null }
                                        </div>

                                        <span><span className="yellow">*</span>  <span dangerouslySetInnerHTML={{__html: t('contact.form.message', {interpolation: {escape: false}})}} /></span>
                                        <div className={"input-box" + (Object.keys(this.state.formInvalidFields).includes('message') ? ' with-error' : '')}>
                                            <textarea name="message"/>
                                            { Object.keys(this.state.formInvalidFields).includes('message') ? <span className="invalid-message" dangerouslySetInnerHTML={{__html: t(this.state.formInvalidFields.message, {interpolation: {escape: false}})}} /> : null }
                                        </div>

                                        <span><span className="yellow">*</span>  <Trans i18nKey={'contact.form.required-fields'}/></span><br/><br/>

                                        <div className={"checkbox-box" + (Object.keys(this.state.formInvalidFields).includes('gdpr-check') ? ' with-error' : '')}>
                                            <input type="hidden" name="gdpr-check" value={0}/>
                                            <input type="checkbox" name="gdpr-check" className="gdpr-check" value={1}/>
                                            <span dangerouslySetInnerHTML={{__html: t('contact.form.gdpr-text', {interpolation: {escape: false}})}} /><br/>
                                        </div>
                                        <button className="arrow-right-white" dangerouslySetInnerHTML={{__html: t('contact.form.send-message', {interpolation: {escape: false}})}} />
                                    </form>
                                }
                            </div>
                        </div>
                    </section>

                    <section id="contact-persons">
                        <div className="row">
                            {this.state.officeManagers ? this.state.officeManagers.map((people, index) => <ContactOfficeManager key={index} index={index} id={people.id} country={people.country} photo={people.photo} name={people.name} email={people.email} phone={people.phone} location={people.location} moto={people.moto}/>) : null}
                        </div>
                    </section>

                    <section id="contact-map">
                        <img src={MapaContact} alt=""/>
                    </section>
                </div>

                <div className="container-fluid fade-in-website" id="contact-2">
                    <div className="container">
                        <AboutProjectsBlock/>
                    </div>

                </div>


            </div>
        );
    }
}

export default withTranslation()(Contact);
