import React, {Component} from 'react';
import {Trans, withTranslation} from "react-i18next";
import {Link} from "react-router-dom";

class ServiceBox extends Component {
    render() {
        const {i18n, t} = this.props;

        return (
            <div className={"col-xl-3 col-md-6 col-sm-6 services-more-block fade-in-box-" + this.props.id} >
                <div className="inner">
                    <h3>{this.props.name[i18n.language]}</h3>
                    <div className="inner-text">
                        <p>{this.props.perex[i18n.language]}</p>
                    </div>
                    <div className="button-row">
                        {this.props.page ? <Link to={'/' + t('route.' + this.props.page)}><button className="arrow-right-black"><Trans i18nKey={'services-expertise.services.see-more'} /></button></Link> : null }
                    </div>
                </div>
            </div>
        );
    }
}

export default  withTranslation()(ServiceBox);
