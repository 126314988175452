import React, {Component} from 'react';
import './App.css';

import FilterRow from "./ContentComponents/FilterRow";
import ProjectBox from "./ContentComponents/ProjectBox";
import {  withTranslation, Trans } from 'react-i18next';
import InfinityLoading from "./img/infinity.svg";
import ProjectTextBox from "./ContentComponents/ProjectTextBox";
import Helmet from "react-helmet/es/Helmet";


class Projects extends Component {

    constructor(props) {
        super(props);

        this.timeout = null;
        this.page = 1;
        this.requestId = null;
        this.state = {
            activeFilters : {
                services : [],
                expertise : [],
                countries : []
            },
            loaded : false,
            filterChanged : false,
            moreProjects : true,
            loadingMore : false,
            noResults : false,
            projects : [],
            filters : {}
        };

        this.filterOnChecked = this.filterOnChecked.bind(this);
        this.loadMoreProjects = this.loadMoreProjects.bind(this);
    }

    loadProjects(loadMore = false) {
        let url = new URL(window.API + '/projects/list');
        Object.keys(this.state.activeFilters).forEach(key => url.searchParams.append(key, this.state.activeFilters[key]));

        if (loadMore) {
            this.page = this.page + 1;
            url.searchParams.append('page', this.page);
            this.setState({ loadingMore :  true });
        } else {
            this.requestId = (new Date()).getTime().toString();
            url.searchParams.append('requestId', this.requestId);
        }

        fetch(url)
            .then(response => response.json())
            .then(data => {
                if (data.requestId && this.requestId !== data.requestId) { return; }
                this.setState(state => {
                    if (loadMore) {
                        state.projects.push(...data.projects);
                    } else {
                        state.projects = data.projects;
                    }

                    state.noResults = data.projects.length === 0 && data.end;
                    state.moreProjects = !data.end;
                    state.filterChanged = false;
                    state.loadingMore = false;
                    state.loaded = true;
                    return state;
                });
            });
    }

    componentDidMount() {
        // $(window).scrollTop(0);

        let url = new URL(window.location);
        if (url.searchParams.get('service') !== null) {
            this.setState(state => {
                state.activeFilters['services'].push(url.searchParams.get('service'));
                state.filterChanged = true;
                return state;
            });
        }

        if (url.searchParams.get('expertise') !== null) {
            this.setState(state => {
                state.activeFilters['expertise'].push(url.searchParams.get('expertise'));
                state.filterChanged = true;
                return state;
            });
        }

        fetch(window.API + '/projects/get-filters')
            .then(response => response.json())
            .then(data => this.setState({ filters : data }));

        this.loadProjects();
    }

    loadMoreProjects() {
        this.loadProjects(true);
    }

    componentDidUpdate(prevProps, prevState) {
        if (this.state.filterChanged === true) {
            this.loadProjects();
            this.setState({ filterChanged : false });
        }
    }

    filterOnChecked(event) {
        let checkbox = event.target;
        let property = checkbox.name.replace('[]', '');

        if (checkbox.checked) {
            this.setState(state => {
                state.activeFilters[property].push(checkbox.value);
                state.filterChanged = true;
                return state;
            });
        } else {
            this.setState(state => {
                let activeFilters = state.activeFilters[property] || [];
                activeFilters = activeFilters.filter(value => value !== checkbox.value);

                state.activeFilters[property] = activeFilters;
                state.filterChanged = true;
                return state;
            });
        }
    }

    render() {
        const { i18n, t } = this.props;

        let serviceFilters = null;
        let expertiseFilters = null;
        let countriesFilters = null;
        let activeFilters = this.state.activeFilters;
        let self = this;

        if (this.state.filters.services !== undefined) {
            let data = this.state.filters.services;

            serviceFilters = Object.keys(data).map(function(value, index) {
                return <FilterRow key={index}  name='services[]'
                           value={value}
                           checked={activeFilters.services.indexOf(value) !== -1}
                           label={data[value][i18n.language]}
                           onChange={self.filterOnChecked}/>
            });
        }

        if (this.state.filters.expertise !== undefined) {
            let data = this.state.filters.expertise;

            expertiseFilters = Object.keys(data).map(function(value, index) {
                return <FilterRow key={index}  name='expertise[]'
                           value={value}
                           checked={activeFilters.expertise.indexOf(value) !== -1}
                           label={data[value][i18n.language]}
                           onChange={self.filterOnChecked}/>
            });
        }

        if (this.state.filters.countries !== undefined) {
            let data = this.state.filters.countries;

            countriesFilters = Object.keys(data).map(function(value, index) {
                return <FilterRow key={index}  name='countries[]'
                           value={value}
                           checked={activeFilters.countries.indexOf(value) !== -1}
                           label={data[value][i18n.language]}
                           onChange={self.filterOnChecked}/>
            });
        }

        let indexOffset = 0;

        return (
            <div>
                <Helmet>
                    <title>{i18n.exists('projects.seo.title') ? t('projects.seo.title') : "Siebert+Talaš"}</title>
                    <meta name="description" content={i18n.exists('projects.seo.description') ? t('projects.seo.description') : ""} />
                    <meta property="og:title" content={i18n.exists('projects.seo.title') ? t('projects.seo.title') : "Siebert+Talaš"} />
                    <meta property="og:description" content={i18n.exists('projects.seo.description') ? t('projects.seo.description') : ""} />
                </Helmet>
                <div className="container content fade-in-website" id="projects">
                    <section id="projects-header">
                        <div className="gray-background"/>
                        <div className="row">
                            <div className="col-md-4">
                            </div>
                            <div className="col-md-8">
                                <h1 dangerouslySetInnerHTML={{__html: t("projects.header.headline", {interpolation: {escape: false}})}} />
                            </div>
                        </div>
                        <div className="projects-filters">
                            <div className="row">
                                <div className="col-lg-2 col-md-8  projects-filter-block filter-image">
                                    <div className="projects-filter-block-headline">
                                        <h3 dangerouslySetInnerHTML={{__html: t("projects.filter.headline", {interpolation: {escape: false}})}} />
                                    </div>

                                </div>
                                <div className="col-lg-3 col-md-4  projects-filter-block">
                                    <div className="projects-filter-block-headline">
                                        <h3 dangerouslySetInnerHTML={{__html: t("projects.filter.services.headline", {interpolation: {escape: false}})}} />
                                    </div>

                                    <ul>
                                        {serviceFilters}
                                    </ul>
                                </div>
                                <div className="col-lg-5 col-md-8  expertise  projects-filter-block">
                                    <div className="projects-filter-block-headline">
                                        <h3 dangerouslySetInnerHTML={{__html: t("projects.filter.expertise.headline", {interpolation: {escape: false}})}} />
                                    </div>
                                    <ul>
                                        {expertiseFilters}
                                    </ul>
                                </div>
                                <div className="col-lg-2 col-md-4  projects-filter-block">
                                    <div className="projects-filter-block-headline">
                                        <h3 dangerouslySetInnerHTML={{__html: t("projects.filter.countries.headline", {interpolation: {escape: false}})}} />
                                    </div>
                                    <ul>
                                        {countriesFilters}
                                    </ul>
                                </div>
                            </div>
                        </div>
                    </section>

                    <section id="projects-content">
                        <div className="row">
                            {this.state.projects.map((project, index) => {
                                if (this.state.projects.length >= 6) {
                                    if (index === 5) {
                                        indexOffset += 1;
                                        return [<ProjectTextBox key={index} side={(index+1) % 2 === 0 ? 'right' : 'left'} />, <ProjectBox key={index+indexOffset} side={(index+indexOffset+1) % 2 === 0 ? 'right' : 'left'} id={project.id} image={project.image} title={project.title} slug={project.slug} tags={project.tags}/>];
                                    }
                                } else if ( this.state.projects.length - 1 === index ) {
                                    return [<ProjectBox key={index+indexOffset} side={(index+indexOffset+1) % 2 === 0 ? 'right' : 'left'} id={project.id} image={project.image} title={project.title} slug={project.slug} tags={project.tags}/>, <ProjectTextBox key={index+indexOffset} side={(index+indexOffset+2) % 2 === 0 ? 'right' : 'left'} />];
                                }

                                return <ProjectBox key={index+indexOffset} side={(index+indexOffset+1) % 2 === 0 ? 'right' : 'left'} id={project.id} image={project.image} title={project.title} slug={project.slug} tags={project.tags}/>
                            })}
                            {this.state.noResults ? <div className={'text-center'} dangerouslySetInnerHTML={{__html: t("projects.list.no_results", {interpolation: {escape: false}})}} /> : null}
                        </div>
                        <div className="row loaderProjects">
                            { !this.state.loaded ?  <img alt="Loading..."  className="infinityLoading" src={InfinityLoading}/> : null }
                        </div>
                    </section>

                    <section id="projects-load-more">
                        <div className="row">
                            { this.state.moreProjects && this.state.loaded && !this.state.loadingMore ? <button className="arrow-down-black button-bottom"  onClick={this.loadMoreProjects}><Trans i18nKey={'projects.loadMoreButton.text'}/></button> : null }
                        </div>
                        <div className="row loaderProjects">
                            { this.state.loadingMore ?  <img className="infinityLoading" alt="Loading..." src={InfinityLoading}/> : null }
                        </div>
                    </section>

                </div>


            </div>
        );
    }
}

export default withTranslation()(Projects);
