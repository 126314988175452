import React, {Component} from 'react';
import './App.css';
import $ from 'jquery';
import {Link} from 'react-router-dom';
import OurProcessIcons from "./ContentComponents/OurProcessIcons";
import BIMProjecting from "./ContentComponents/BIMProjecting";
import ProjectSlider from "./ContentComponents/ProjectSlider";
import HomeAboutSliderItem from "./ContentComponents/HomeAboutSliderItem";
import homeAboutSlideImage1 from "./img/about-slide-img-1.svg";
import homeAboutSlideImage2 from "./img/about-slide-img-2.svg";
import homeAboutSlideImage3 from "./img/about-slide-img-3.svg";
import meetUsPersonalYellowLines from "./img/meet-us-personal-yellow-lines.svg";

import meetUsPersonalYellowLinesMobile from "./img/mobile-stripes-partner.svg";

import bigseebanner from "./img/bannerbigsee.jpg";
import bigseebanner23 from "./img/bannerbigsee-23.jpg";
import { withTranslation, Trans } from 'react-i18next';
import Slider from "react-slick";
import InfinityLoading from "./img/infinity.svg";

import {Link as LinkScroll} from "react-scroll";
import AnimatedDots from "./ContentComponents/AnimatedDots";
import {Helmet} from "react-helmet";
import QualityPoliticsBlock from "./ContentComponents/QualityPoliticsBlock";
import Poster from "./ContentComponents/Poster";
import plakatSk from "./img/plakat-sk.svg";


class MainHome extends Component {
    constructor(props) {
        super(props);

        this.state = {
            loaded: false,
            projects: [],
            services: [],
            expertise: [],
            homepageHero : {}
        }
    };


    componentDidMount() {
        $(window).scrollTop(0);

        let url = new URL(window.API + '/homepage-hero/get-current');
        fetch(url)
            .then(response => response.json())
            .then(data => this.setState({ homepageHero : data }));

        url = new URL(window.API + '/projects/homepage');
        fetch(url)
            .then(response => response.json())
            .then(data => this.setState({ projects : data }));

        url = new URL(window.API + '/services/list');
        fetch(url)
            .then(response => response.json())
            .then(data => this.setState({ services : data }));

        url = new URL(window.API + '/expertise/list');
        fetch(url)
            .then(response => response.json())
            .then(data => this.setState({ expertise : data, loaded : true }));
    }

    render() {
        const {i18n, t} = this.props;
        var settings = {
            dots: true,
            infinite: false,
            speed: 500,
            slidesToShow: 1,
            slidesToScroll: 1,
            initialSlide: 0
        };
        return (
            <div>
                <Helmet>
                    <title>{i18n.exists('home.seo.title') ? t('home.seo.title') : "Siebert+Talaš"}</title>
                    <meta name="description" content={i18n.exists('home.seo.description') ? t('home.seo.description') : ""} />
                    <meta property="og:title" content={i18n.exists('home.seo.title') ? t('home.seo.title') : "Siebert+Talaš"} />
                    <meta property="og:description" content={i18n.exists('home.seo.description') ? t('home.seo.description') : ""} />
                </Helmet>
                <div className="container content fade-in-website home" id="home">
                    { !this.state.loaded ?    <div className="row loaderProjects"><img alt="Loading..."  className="infinityLoading" src={InfinityLoading}/></div> : null }
                    { Object.keys(this.state.homepageHero).length ?
                    <section id="home-header" style={{backgroundImage: `linear-gradient(rgba(0,0,0,0.9), rgba(0,0,0,0.4)), url(${this.state.homepageHero.image})`}}>
                        <div className="row">
                            <div className="col-lg-12 col-md-12 home-header-description">
                                <h1>{this.state.homepageHero.headline[i18n.language]}</h1>
                                <h3>{this.state.homepageHero.subheadline[i18n.language]}</h3>
                            </div>
                        </div>
                        <div className="row home-header-buttons">
                            <div className="col-md-5 col-lg-4">
                                <Link to={'/'+ t('route.projects')}>
                                    <button className="arrow-right-white" dangerouslySetInnerHTML={{__html: t("home.header.show-projects", {interpolation: {escape: false}})}} />
                                </Link>
                            </div>
                            <div className="col-md-5 col-lg-4">
                                <LinkScroll to="more-projects"
                                        spy={true}
                                        smooth={true}
                                        offset={-70}
                                        duration= {500}>
                                    <button className="arrow-down-white button-bottom" dangerouslySetInnerHTML={{__html: t("home.header.show-more", {interpolation: {escape: false}})}} />
                                </LinkScroll>

                            </div>
                        </div>
                    </section> : null }
                    <ProjectSlider projects={this.state.projects}/>
                    <a className={"bigseelink"} href={"https://bigsee.eu/profiles/siebert-talas-spol-s-r-o/"} target={"_blank"}><img src={bigseebanner}/></a>
                    <a className={"bigseelink"} href={"https://bigsee.eu/profiles/siebert-talas-spol-s-r-o/"} target={"_blank"}><img src={bigseebanner23}/></a>


                    <div className="video-container">
                        <iframe src="https://www.youtube.com/embed/gxrvfU8y7tY" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowFullScreen />
                    </div>
                    <h2 className="video-title">Pavilón ExpoDubaj 2021</h2>
                    <BIMProjecting/>

                </div>
                { this.state.loaded ?
                <section className="home" id="home-services-expertise">
                    <div className="container">
                        <h2 dangerouslySetInnerHTML={{__html: t("home.services-expertise.title", {interpolation: {escape: false}})}} />
                        <div className="row services-expertise-box">
                            <div className="col-lg-3 offset-lg-1 services-box">
                                <h3 className="services-expertise-box-header" dangerouslySetInnerHTML={{__html: t("home.services-expertise.services.title", {interpolation: {escape: false}})}} />
                                <div className="services-expertise-box-content">
                                    <ul>
                                        {this.state.services.map((service, index) => <li key={index}><Link to={"/" +  t('route.projects') + "?service=" + service.id}>{service.name[i18n.language]}</Link></li>)}
                                    </ul>
                                </div>
                            </div>
                            <div className="col-lg-7 expertise-box">
                                <h3 className="services-expertise-box-header" dangerouslySetInnerHTML={{__html: t("home.services-expertise.expertise.title", {interpolation: {escape: false}})}} />

                                <div className="services-expertise-box-content">
                                    <div className="expertise-list-container">
                                        {this.state.expertise.community !== undefined ?
                                            <ul>
                                                <li dangerouslySetInnerHTML={{__html: t("home.services-expertise.expertise.community", {interpolation: {escape: false}})}} />


                                                <ul className="black-ul">
                                                    {this.state.expertise.community.map((expertise, index) => <li key={index}><Link to={"/" + t('route.projects') + "?expertise=" + expertise.id}>{expertise.name[i18n.language]}</Link></li>)}
                                                </ul>
                                            </ul> : null}
                                    </div>
                                    <div className="expertise-list-container">
                                        {this.state.expertise.lifestyle !== undefined ?
                                            <ul>
                                                <li dangerouslySetInnerHTML={{__html: t("home.services-expertise.expertise.lifestyle", {interpolation: {escape: false}})}} />
                                                <ul className="black-ul">
                                                    {this.state.expertise.lifestyle.map((expertise, index) => <li key={index}><Link to={"/" + t('route.projects') + "?expertise=" + expertise.id}>{expertise.name[i18n.language]}</Link></li>)}
                                                </ul>
                                            </ul> : null}
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
                    : null }

                { this.state.loaded ?
                    <section className="home" id="home-quality-politics">
                        <div className={"container"}>
                    <QualityPoliticsBlock/>
                        </div>
                    </section>
                    : null }

                { this.state.loaded ?
                <div className="container home" id="home-3">
                    <section id="home-about-us">
                        <h2 dangerouslySetInnerHTML={{__html: t("home.about-us.title", {interpolation: {escape: false}})}} />
                        <div className="row">

                            <div className="col-md-7">
                                <span dangerouslySetInnerHTML={{__html: t("home.about-us.description", {interpolation: {escape: false}})}} />
                            </div>
                            <div className="col-md-5">
                                <Link to={'/'+ t('route.about-us')}>
                                    <button className="arrow-right-black" dangerouslySetInnerHTML={{__html: t("home.about-us.read-more", {interpolation: {escape: false}})}} />
                                </Link>
                            </div>
                        </div>
                        <div className="about-us-slider-container">
                            <Slider {...settings}>
                                <HomeAboutSliderItem photo={homeAboutSlideImage1} title={<Trans i18nKey={'home.about-us.slider.wide-range.title'}/>} description={<Trans i18nKey={'home.about-us.slider.wide-range.content'}/>}/>
                                <HomeAboutSliderItem photo={homeAboutSlideImage2} title={<Trans i18nKey={'home.about-us.slider.sustainability.title'}/>} description={<Trans i18nKey={'home.about-us.slider.sustainability.content'}/>}/>
                                <HomeAboutSliderItem photo={homeAboutSlideImage3} title={<Trans i18nKey={'home.about-us.slider.people.title'}/>} description={<Trans i18nKey={'home.about-us.slider.people.content'}/>}/>
                            </Slider>
                        </div>
                    </section>

                </div>
                    : null }
                { this.state.loaded ?
                <section className="home" id="home-process">
                    <div className="container">
                        <h2 dangerouslySetInnerHTML={{__html: t("home.our-process.title", {interpolation: {escape: false}})}} />
                        <div className="row">
                            <div className="col-md-8">
                                <span dangerouslySetInnerHTML={{__html: t("home.our-process.description", {interpolation: {escape: false}})}} />
                            </div>
                        </div>

                        <OurProcessIcons/>
                    </div>
                </section>
                    : null }
                { this.state.loaded ?
                <div className="container home" id="home-5">


                    <section id="home-partner">
                        <div className="row">
                            <div className="col-md-10 offset-md-1 home-partner-text">
                                <img className="desktop float-left d-none d-xl-block" src={meetUsPersonalYellowLines} alt=""/>
                                <h2 dangerouslySetInnerHTML={{__html: t("home.partner.title", {interpolation: {escape: false}})}} />
                                <div className="home-partner-dots-box">
                                    <img className="mobile float-left d-block d-xl-none" src={meetUsPersonalYellowLinesMobile} alt=""/>
                                    <div className="float-left">
                                        <Link to={'/'+ t('route.contact')} className={'home-partner-link-contact'}>
                                            <button className="arrow-right-black" dangerouslySetInnerHTML={{__html: t("home.partner.contact-button", {interpolation: {escape: false}})}} />
                                        </Link>
                                        <Link to={'/'+ t('route.projects')} className={'home-partner-link-projects'}>
                                            <button className="arrow-right-black" dangerouslySetInnerHTML={{__html: t("home.partner.allprojects-button", {interpolation: {escape: false}})}} />
                                        </Link>
                                    </div>
                                    <AnimatedDots cols={5} rows={4} spaces={55} spacesY={38} size={14} color={'gray'} />
                                </div>

                            </div>

                        </div>
                    </section>


                </div>
                    : null }


                {/*{ i18n.language === "sk" ? <Poster /> : null }*/}
            </div>
        );
    }
}

export default withTranslation()(MainHome);
