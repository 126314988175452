import i18n from "i18next";

export default function getLocalizedRoute(lang, t, state) {
    state = state || {};

    let url = new URL(window.location.href);

    let pathname = url.pathname;

    if (pathname.split('/').length > 2 && pathname.split('/')[2] !== '') {
        pathname = pathname.split('/').slice(0, (i18n.language === 'en' ? 2 : 3)).join('/');

        let pathLastPart = url.pathname.replace(pathname, '');
        if (state && state.project && state.project.id !== undefined) {
            if (state.project.slug !== undefined && state.project.slug[lang].trim().length > 0) {
                pathLastPart = '/' + state.project.slug[lang].trim();
            } else {
                pathLastPart = '/' + state.project.id;
            }
        }

        return ('/' + t(window.routes[pathname], { lng: lang })).replace('//', '/') + pathLastPart;
    } else if (['/', '/sk/', '/cs/', '/ru/'].includes(pathname)) {
        return (lang !== 'en'  ? '/' + lang + '/' : '/');
    }

    return  ('/' + t(window.routes[pathname], { lng: lang })).replace('//', '/');
}