import React, {Component} from 'react';

class ProcessIcon extends Component
{
    render() {
        return <div className="col-md-3 our-process-icon-holder">
            <div className="white-bubbler">{this.props.number}</div>

            <img src={this.props.img} alt=""/>
            <span className="process-description">{this.props.description}</span>
        </div>
    }
}

export default ProcessIcon;