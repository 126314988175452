import React, {Component} from 'react';
import ReactDOM from 'react-dom';

class AnimatedDots extends Component {
    constructor(props){
        super(props);

        this.handleMouseMove = this.handleMouseMove.bind(this);
        this.magnetRange = 120;
        this.speedIndex = 0.15;
    }

    componentDidMount() {
        document.getElementsByTagName('body')[0].addEventListener('mousemove', this.handleMouseMove);
    }

    componentWillUnmount() {
        document.getElementsByTagName('body')[0].removeEventListener('mousemove', this.handleMouseMove);
    }

    handleMouseMove(e) {
        // e.pageX, e.pageY

        let componentRect = ReactDOM.findDOMNode(this).getBoundingClientRect();
        if (
            e.clientX >= componentRect.x - 120
              && e.clientX <= componentRect.x + componentRect.width + 120
              && e.clientY >= componentRect.y - 120
              && e.clientY <= componentRect.y + componentRect.height + 120
        ) {
            let _mousePos = {};
            _mousePos.x = e.clientX - componentRect.x;
            _mousePos.y = e.clientY - componentRect.y;

            let dots = ReactDOM.findDOMNode(this).childNodes;

            dots.forEach(dot => {
                let __mousePos = {};
                __mousePos.x = _mousePos.x - dot.offsetLeft - (this.props.size / 2);
                __mousePos.y = _mousePos.y - dot.offsetTop - (this.props.size / 2);

                // Get distance and angle between mouse pointer and dot
                let dst = Math.sqrt(Math.pow(__mousePos.x, 2) + Math.pow(__mousePos.y, 2));
                let theta = Math.atan2(__mousePos.y, __mousePos.x);

                // Magnetism
                let force = (this.magnetRange - dst) * this.speedIndex;
                if (force < 0) force = 0;

                dot.style.transform = 'translate(' + (0 - force * Math.cos(theta)) + 'px, ' + (0 - force * Math.sin(theta)) + 'px)';
            });
        }
    }

    renderDots() {
        let dots = [];

        let _top, _left = 0;

        for (let y = 0; y < this.props.rows; y++) {
            for (let x = 0; x < this.props.cols; x++) {
                _top = y * ((this.props.spacesY || this.props.spaces) + this.props.size);
                _left = x * (this.props.spaces + this.props.size);
                dots.push(<div key={y + "_ " + x} className="animated-dots__dot" id={"dot_" + y + "_" + x} style={{width: this.props.size, height: this.props.size, left: _left, top: _top}} />);
            }
        }

        return dots;
    }

    render() {
        let _width, _height = 0;

        _height = this.props.rows * ((this.props.spacesY || this.props.spaces) + this.props.size) - (this.props.spacesY || this.props.spaces);
        _width = this.props.cols * (this.props.spaces + this.props.size) - this.props.spaces;

        let className = this.props.className !== undefined ? " " + this.props.className : "";
        className += this.props.color !== undefined ? " animated-dots--" + this.props.color : "";

        return (
            <div className={"animated-dots" + className} style={{width: _width, height: _height}}>
                {this.renderDots()}
            </div>
        );
    }
}

export default AnimatedDots;
