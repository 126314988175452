import React, {Component} from 'react';
import './App.css';
import $ from 'jquery';

import ProjectSlider from "./ContentComponents/ProjectSlider";
import {Link} from 'react-router-dom';
import {withTranslation, Trans} from "react-i18next";
import {Helmet} from "react-helmet";

class Architecture extends Component {
    constructor(props) {
        super(props);

        this.state = {
            id: null,
            moreProjects: [],
            loaded: false
        };
    }

    componentDidMount() {
        $(window).scrollTop(0);

        let url = new URL(window.API + '/services/detail/architecture');
        fetch(url)
            .then(response => response.json())
            .then(data => this.setState({id: data.id, moreProjects: data.projects, loaded: true}));
    }

    renderMoreProjects() {
        const {t} = this.props;
        return <div className="container" id="bim-3">

            <section id="bim-more-projects">
                <div className="row">
                    <h2 dangerouslySetInnerHTML={{__html: t('architecture.more-projects.headline', {interpolation: {escape: false}})}} />
                </div>
                <ProjectSlider projects={this.state.moreProjects}/>

            </section>

            {this.state.moreProjects.length > 9 ?
                <div className="row">
                    <Link to={"/" + t('route.projects') + "?service=" + this.state.id}>
                        <button dangerouslySetInnerHTML={{__html: t('architecture.more-projects.description', {interpolation: {escape: false}})}} />
                    </Link>
                </div> : null}

        </div>
    }

    render() {
        const {i18n, t} = this.props;

        return (
            <div>
                <Helmet>
                    <title>{i18n.exists('architecture.seo.title') ? t('architecture.seo.title') : "Siebert+Talaš"}</title>
                    <meta name="description" content={i18n.exists('architecture.seo.description') ? t('architecture.seo.description') : ""} />
                    <meta property="og:title" content={i18n.exists('architecture.seo.title') ? t('architecture.seo.title') : "Siebert+Talaš"} />
                    <meta property="og:description" content={i18n.exists('architecture.seo.description') ? t('architecture.seo.description') : ""} />
                </Helmet>
                <section id="bim-header">
                    <div className="container content fade-in-website">
                        <h1 dangerouslySetInnerHTML={{__html: t('architecture.header.headline', {interpolation: {escape: false}})}} />
                        <p className="gray" dangerouslySetInnerHTML={{__html: t('architecture.header.description', {interpolation: {escape: false}})}} />
                    </div>
                </section>

                <section id="bim-content">
                    <div className="container">
                        <div className="row">
                            <div className="col-md-6 order-md-2">
                                <img src="/img/bim-screen.png" className="img-bim-screen" alt=""/>
                            </div>
                            <div className="col-md-6">
                                <h3 dangerouslySetInnerHTML={{__html: t('architecture.content.headline1', {interpolation: {escape: false}})}} />
                                <span dangerouslySetInnerHTML={{__html: t('architecture.content.description1', {interpolation: {escape: false}})}} />
                            </div>
                        </div>

                        <div className="row">
                            <div className="col-md-6">
                                <h3 dangerouslySetInnerHTML={{__html: t('architecture.content.headline2', {interpolation: {escape: false}})}} />
                                <span dangerouslySetInnerHTML={{__html: t('architecture.content.description2', {interpolation: {escape: false}})}} />
                            </div>
                            <div className="col-md-6">
                                <h3 dangerouslySetInnerHTML={{__html: t('architecture.content.headline3', {interpolation: {escape: false}})}} />
                                <span dangerouslySetInnerHTML={{__html: t('architecture.content.description3', {interpolation: {escape: false}})}} />
                            </div>
                        </div>

                        <div className="row third-row">
                            <div className="col-md-6">
                                <h3 dangerouslySetInnerHTML={{__html: t('architecture.content.headline4', {interpolation: {escape: false}})}} />
                                <span dangerouslySetInnerHTML={{__html: t('architecture.content.description4', {interpolation: {escape: false}})}} />
                            </div>
                            <div className="col-md-6">
                                <h3 dangerouslySetInnerHTML={{__html: t('architecture.content.headline5', {interpolation: {escape: false}})}} />
                                <span dangerouslySetInnerHTML={{__html: t('architecture.content.description5', {interpolation: {escape: false}})}} />
                            </div>
                        </div>

                        <img src="/img/bim-big.png" className="img-bim-building" alt=""/>
                    </div>
                </section>

                {this.state.moreProjects.length > 0 ? this.renderMoreProjects() : null}
            </div>
        );
    }
}

export default withTranslation()(Architecture);
