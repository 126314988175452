import React, {Component} from 'react';
import '../App.css';
import $ from 'jquery';
import {withTranslation, Trans} from "react-i18next";


class JobRowComponent extends Component {

    constructor(props) {
        super(props);

        const { t } = props;
        t('careers.jobs.fulltime');
        t('careers.jobs.parttime');

        this.showJobMore = this.showJobMore.bind(this);
        this.state = { jobOpened: 0 };
    }

    showJobMore(){
        if(this.state.jobOpened === 0){
            $(".career-job-descritpion").removeClass("activated");
            $("#career-job-description-" + this.props.id).addClass("activated");
            $("#career-job-submit-" + this.props.id).removeClass("arrow-down-black");
            $("#career-job-submit-" + this.props.id).addClass("arrow-top-black");
            this.setState({ jobOpened: 1 });
        } else {
            $(".career-job-descritpion").removeClass("activated");
            this.setState({ jobOpened: 0 });

            $("#career-job-submit-" + this.props.id).addClass("arrow-down-black");
            $("#career-job-submit-" + this.props.id).removeClass("arrow-top-black");
        }
    }

    render() {
        const { t, i18n } = this.props;

        let innerHtml = this.props.description;
        if(this.props.location[i18n.language] === "Bratislava"){
            innerHtml += '<a href="mailto:kariera@sieberttalas.com?subject=KARIERA" class="job-apply-btn">'+ t("careers.jobs.apply-btn") + '</a>';
        } else {
            innerHtml += '<a href="mailto:praha@sieberttalas.com?subject=KARIERA" class="job-apply-btn">'+ t("careers.jobs.apply-btn") + '</a>';
        }


        return (
            <div>
                <div className="row career-job" id="career-job">
                    <div className="col-md-6 inner-job">
                        <span className="career-job-name">{this.props.jobTitle[i18n.language]}</span>
                    </div>

                    <div className="col-xl-4 col-md-3 col-6 inner-job">
                        <span className="career-job-city">{this.props.location[i18n.language]}</span>
                        <span className="career-job-type"><Trans i18nKey={'careers.jobs.' + this.props.type}/></span>
                    </div>

                    <div className="col-xl-2 col-md-3 col-6 inner-job">
                        <button id={"career-job-submit-" + this.props.id} className="career-job-submit arrow-down-black" onClick={this.showJobMore}><span ><Trans i18nKey={'careers.jobs.see-more-btn'}/></span></button>
                    </div>
                </div>
                <div className="career-job-descritpion" id={"career-job-description-" + this.props.id}  dangerouslySetInnerHTML={{__html: innerHtml}} />
            </div>
        );
    }
}

export default withTranslation()(JobRowComponent);
