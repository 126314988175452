import React, {Component} from 'react';
import {withTranslation} from "react-i18next";


class TypicalDaySliderItem extends Component {
    render() {
        return (
            <div>
                <div className="typical-day-block">

                    <img src={this.props.photo} alt=""/>
                    <h3>{this.props.time}</h3>
                    <span>{this.props.description}</span>
                </div>
            </div>
        );
    }
}

export default withTranslation()(TypicalDaySliderItem);
