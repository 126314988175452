import React, {Component} from 'react';
import '../App.css';

import {Link} from 'react-router-dom';

import {withTranslation, Trans} from "react-i18next";
import AnimatedDots from "./AnimatedDots";
import PolicitsPartners from "../img/policits_partners.jpg";

class QualityPoliticsBlock extends Component {


    componentDidMount() {

    }


    render() {
        const {t} = this.props;

        return (
            <section className="quality-politics-component">

                <div className="row quality-politics-header">
                    <div className="col-md-12 quality-politics-header-inner">
                        <h2 dangerouslySetInnerHTML={{__html: t('quality-politics.header.headline', {interpolation: {escape: false}})}} />
                        <span dangerouslySetInnerHTML={{__html: t('quality-politics.text', {interpolation: {escape: false}})}} />
                    </div>
                </div>

                <div className="row">

                    <div className="col-md-6 bim-image">
                        <img src={PolicitsPartners}/>
                    </div>

                    <div className="col-md-6 quality-politicts-button">
                        <AnimatedDots cols={8} rows={5} spaces={30} size={10}/>
                        <Link to={'/' + t('route.quality-politics')}>
                            <button className="arrow-right-black">
                                <Trans i18nKey={'quality-politics.bottom.more-button'}/>
                            </button>
                        </Link>

                    </div>
                </div>



            </section>
        );
    }
}



export default withTranslation()(QualityPoliticsBlock);
