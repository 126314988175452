import React, {Component} from 'react';

class ProjectDetailImage extends Component
{
    render() {
        return <div className="project-detail-image-box">
            <img src={this.props.src} alt=""/>
        </div>
    }
}
export default ProjectDetailImage;