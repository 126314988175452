import React, {Component} from 'react';
import {withTranslation} from "react-i18next";


class ContactOfficeManager extends Component {
    render() {
        const {i18n} = this.props;

        return <div className={"col-md-6" + ((this.props.index + 1) % 2 === 0 ? ' second' : '')}>
            <div className="row">
                <div className="col-lg-5">
                    <img src={this.props.photo} alt=""/>
                </div>
                <div className="col-lg-7">
                    <h3>{this.props.name}</h3>
                    <span>
                    {this.props.email ? <span>{this.props.email}<br /></span> : null}
                        {this.props.phone ? <span>{this.props.phone}<br /></span> : null}
                        {this.props.location[i18n.language]}
                </span>
                </div>
            </div>
        </div>
    }
}

export default withTranslation()(ContactOfficeManager);