import React, {Component} from 'react';
import './App.css';
import $ from 'jquery';
import Lightbox from 'lightbox2';
import 'lightbox2/src/css/lightbox.css';

import {withTranslation, Trans} from "react-i18next";
import PolicitsPartners from "./img/policits_partners.jpg";




class QualityPolitics extends Component {
    constructor(props) {
        super(props);

        this.state = {
            id : null,
            loaded : false,
            certificates : []
        };
    }

    componentDidMount() {
        $(window).scrollTop(0);

        let url = new URL(window.API + '/certificates/list');
        fetch(url)
            .then(response => response.json())
            .then(data => this.setState({ certificates : data, loaded : true }));
    }




    render() {
        const {i18n, t} = this.props;


        return (
            <div>


                <section id="quality-policits-page-header">
                    <div className="container content fade-in-website" id="quality-politics">
                        <img src={PolicitsPartners}/>
                        <h1 dangerouslySetInnerHTML={{__html: t('quality-politics-page.header.headline', {interpolation: {escape: false}})}} />
                        <p className="gray" dangerouslySetInnerHTML={{__html: t('quality-politics-page.header.description', {interpolation: {escape: false}})}} />

                    </div>
                </section>

                <section id="quality-policits-page-content">
                    <div className="container">

                        <h2 dangerouslySetInnerHTML={{__html: t('quality-politics-page.content.main-headline', {interpolation: {escape: false}})}} />

                            <div className="row ">

                                <div className="col-md-6 quality-politics-page-block">
                                    <h3 dangerouslySetInnerHTML={{__html: t('quality-politics-page.content.headline1', {interpolation: {escape: false}})}} />

                                    <ul dangerouslySetInnerHTML={{__html: t('quality-politics-page.content.list1', {interpolation: {escape: false}})}} />


                                </div>
                                <div className="col-md-6  quality-politics-page-block">
                                    <h3 dangerouslySetInnerHTML={{__html: t('quality-politics-page.content.headline2', {interpolation: {escape: false}})}} />

                                    <ul dangerouslySetInnerHTML={{__html: t('quality-politics-page.content.list2', {interpolation: {escape: false}})}} />


                                </div>
                            </div>

                        <div className="row ">

                            <div className="col-md-6  quality-politics-page-block">
                                <h3 dangerouslySetInnerHTML={{__html: t('quality-politics-page.content.headline3', {interpolation: {escape: false}})}} />
                                <ul dangerouslySetInnerHTML={{__html: t('quality-politics-page.content.list3', {interpolation: {escape: false}})}} />

                            </div>
                            <div className="col-md-6  quality-politics-page-block">
                                <h3 dangerouslySetInnerHTML={{__html: t('quality-politics-page.content.headline4', {interpolation: {escape: false}})}} />
                                <ul dangerouslySetInnerHTML={{__html: t('quality-politics-page.content.list4', {interpolation: {escape: false}})}} />

                            </div>
                        </div>

                        { this.state.loaded ?
                        <div className="row quality-politics-certificates">

                            {this.state.certificates[i18n.language] !== undefined ? this.state.certificates[i18n.language].map((certificate, index) =>
                                (certificate.thumbnail) ?
                            <div className="col-md-4" key={index}>
                                <a href={ certificate.image } data-lightbox="certificates" data-title={ certificate.name[i18n.language] }>
                                    <img src={ certificate.thumbnail }/>
                                </a>
                            </div> : null) : null
                            }

                        </div>
                        : null }
                        <div className={"row segoe"}>
                            <div className={"col-md-4"}>
                                <h2> Matej Siebert</h2>
                            </div>
                            <div className={"col-md-4"}>
                                <h2>Roman Talaš </h2>
                            </div>
                            <div className={"col-md-4"}>
                                <h2>Petr Vašina</h2>
                            </div>
                        </div>

                    </div>

                </section>




            </div>
        );
    }
}

export default  withTranslation()(QualityPolitics);
