import React, {Component} from 'react';
import './App.css';
import $ from 'jquery';
// or less ideally
import AboutProjectsBlock from './ContentComponents/AboutProjectsBlock';

import servicesStripesGray from "./img/services-stripes-gray.svg";
import ServiceBox from "./ContentComponents/ServiceBox";
import {withTranslation, Trans} from "react-i18next";
import {Link} from "react-router-dom";
import AnimatedDots from "./ContentComponents/AnimatedDots";
import {Helmet} from "react-helmet";
import QualityPoliticsBlock from "./ContentComponents/QualityPoliticsBlock";


class ServicesExpertise extends Component {

    constructor(props) {
        super(props);

        this.state = {
            loaded : false,
            services : [],
            expertise : []
        }
    }


    componentDidMount() {
        $(window).scrollTop(0);

        let url = new URL(window.API + '/services/list');
        fetch(url)
            .then(response => response.json())
            .then(data => this.setState({ services : data }));

        url = new URL(window.API + '/expertise/list');
        fetch(url)
            .then(response => response.json())
            .then(data => this.setState({ expertise : data }));
    }


    render() {
        const {i18n, t} = this.props;

        return (
            <div>
                <Helmet>
                    <title>{i18n.exists('services-expertise.seo.title') ? t('services-expertise.seo.title') : "Siebert + Talaš"}</title>
                    <meta name="description" content={i18n.exists('services-expertise.seo.description') ? t('services-expertise.seo.description') : ""} />
                    <meta property="og:title" content={i18n.exists('services-expertise.seo.title') ? t('services-expertise.seo.title') : "Siebert+Talaš"} />
                    <meta property="og:description" content={i18n.exists('services-expertise.seo.description') ? t('services-expertise.seo.description') : ""} />
                </Helmet>
                <div className="container content fade-in-website" id="services-expertise">
                    <section id="services-header">
                        <div className="row">
                            <div className="col-xl-4 services-headline yellow-box-headline">
                                <AnimatedDots cols={4} rows={5} spaces={50} spacesY={35} size={14} />
                                <h1 dangerouslySetInnerHTML={{__html: t("services-expertise.services-header.title", {interpolation: {escape: false}})}} />
                            </div>
                            <div className="col-xl-7 offset-xl-1 services-header-description">
                                <span dangerouslySetInnerHTML={{__html: t("services-expertise.services-header.description", {interpolation: {escape: false}})}} />
                            </div>
                        </div>
                    </section>
                </div>

                <div className="container-fluid  fade-in-website" id="services-expertise-2">
                    <div className="container">
                        <section id="services-more-blocks">
                            <div className="row">
                                {this.state.services.map((service, index) => <ServiceBox key={index} id={service.id} name={service.name} perex={service.perex} page={service.page} />)}
                            </div>
                            <img src={servicesStripesGray} className="gray-stripes"  alt=""/>
                        </section>
                    </div>
                </div>

                <div className="container content fade-in-website" id="services-expertise-3">
                    <section id="expertise-header">
                        <div className="row">
                            <div className="col-xl-4 expertise-headline yellow-box-headline">
                                <AnimatedDots cols={4} rows={5} spaces={50} spacesY={35} size={14} />
                                <h1 dangerouslySetInnerHTML={{__html: t("services-expertise.expertise-header.title", {interpolation: {escape: false}})}} />
                            </div>
                            <div className="col-xl-7 offset-xl-1 expertise-header-description">
                                <span dangerouslySetInnerHTML={{__html: t("services-expertise.expertise-header.description", {interpolation: {escape: false}})}} />
                            </div>
                        </div>


                    </section>

                    <section className="expertise-blocks">
                        <div className="col-lg-2 col-md-0">

                        </div>
                        <div className="col-lg-4 col-md-6">
                            {this.state.expertise.community !== undefined ? <div className="col-md-10 center">
                                <h3 dangerouslySetInnerHTML={{__html: t("services-expertise.expertise.community", {interpolation: {escape: false}})}} />
                                {this.state.expertise.community.map((expertise, index) => <Link key={index} to={'/' + t('route.projects') + '?expertise=' + expertise.id}><button className="arrow-right-black">{expertise.name[i18n.language]}</button></Link>)}
                                </div> : null}
                        </div>

                        <div className="col-lg-4  col-md-6">
                            {this.state.expertise.lifestyle !== undefined ? <div className="col-md-10 center">
                                <h3 dangerouslySetInnerHTML={{__html: t("services-expertise.expertise.lifestyle", {interpolation: {escape: false}})}} />
                                {this.state.expertise.lifestyle.map((expertise, index) => <Link key={index} to={'/' + t('route.projects') + '?expertise=' + expertise.id}><button className="arrow-right-black">{expertise.name[i18n.language]}</button></Link>)}
                            </div> : null}
                        </div>

                        <div className="col-lg-2 col-md-0">

                        </div>

                    </section>


                    <QualityPoliticsBlock/>

                </div>


                <div className="container-fluid  fade-in-website" id="services-expertise-4">
                    <div className="container">
                        <AboutProjectsBlock/>

                    </div>
                </div>


            </div>
        );
    }
}

export default withTranslation()(ServicesExpertise);
